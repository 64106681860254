import React, { Dispatch } from 'react';

import AgendaPreferencesCheckbox from '@/application/pages/email_preferences/agenda/AgendaPreferencesCheckbox';
import LineDivider from '@/shared/components/LineDivider';
import { CompanyWithIssuePreferences } from '@/shared/types/emailPreferences';

type Props = {
  company: CompanyWithIssuePreferences
  dispatch: Dispatch<any>
  preferences: any
};

function AgendaCompanyRow({ company, preferences, dispatch }:Props) {
  if (company.agendaEnabled === false) {
    return null;
  }

  return (
    <>
      <LineDivider />
      <h3 className="mb-3">{company.name}</h3>
      {company.issues.map(({ id, name }) => (
        <div key={id} className="mb-2">
          <AgendaPreferencesCheckbox
            id={id}
            label={name}
            schedule="agenda"
            state={preferences[id].agenda}
            dispatch={dispatch}
          />
        </div>
      ))}
    </>
  );
}

export default AgendaCompanyRow;
