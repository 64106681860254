import { faCalendarCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { apiAgendaItemsPath } from '@routes';
import React from 'react';
import {
  Card, Col, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import NothingFound from '@/application/components/NothingFound';
import DateTimeLocalized from '@/shared/components/DateTimeLocalized';
import LoadingIndicator from '@/shared/components/LoadingIndicator';
import { useApiCollection } from '@/shared/hooks/useApi';
import { AgendaItemCollection } from '@/shared/types/models';
import { toQueryString } from '@/shared/utilities/querystring';

import AgendaListItem from './AgendaListItem';

type Props = {
  params? :Record<string, unknown>
  suspended?: boolean
};

function AgendaList({
  params = {},
  suspended = true,
}: Props) {
  const { t } = useTranslation();

  const [{ items }, { loading }] = useApiCollection<AgendaItemCollection>(
    {
      url: `${apiAgendaItemsPath()}?${toQueryString(params)}`,
    },
    {
      // not firing requests until component is unsuspended
      // unsuspension is typically done by a filter being initialized
      manual: suspended,
    },
  );
  const isReady = !loading && !suspended;

  return (
    <Row className="mt-4">
      <Col>
        {!loading && items.length > 0 && (
        <Card>
          <Card.Body className="p-5">
            {items.map((collection: AgendaItemCollection) => (
              <div key={collection.date}>
                <div className="text-primary small text-capitalize mb-2">
                  <DateTimeLocalized format="dddd D MMMM YYYY">
                    {collection.date}
                  </DateTimeLocalized>
                </div>

                {collection.events.map((item) => (
                  <AgendaListItem key={item.id} item={item} />
                ))}
              </div>
            ))}
          </Card.Body>
        </Card>
        )}
        <div className="text-center my-4">
          {!isReady && <LoadingIndicator />}
          {isReady && items.length === 0 && (
          <NothingFound
            icon={faCalendarCircleUser}
            title={t('views.agendas.agenda_items.none_exist')}
            subtitle={t('views.agendas.agenda_items.none_exist_explanation')}
          />
          )}
        </div>
      </Col>
    </Row>
  );
}

export default AgendaList;
