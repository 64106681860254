import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

type Props = {
  icon: IconDefinition
  title: string
  subtitle?: string
  button?: JSX.Element
};

function NothingFound({
  icon, title, subtitle, button,
}: Props) {
  return (
    <Row className="my-md-6 my-3" role="alert">
      <Col lg={{ span: 6, offset: 3 }}>
        <div className="mt-md-6 mt-3 mb-5 text-center text-neutral-60">
          <FontAwesomeIcon icon={icon} size="6x" />
        </div>

        <h3>{title}</h3>
        <p>{subtitle}</p>
        {button}
      </Col>
    </Row>
  );
}

export default NothingFound;
