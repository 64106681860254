import {
  faAlarmPlus, faAlbumCollection, faHouse,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  Button, Card, CardGroup, Col, Modal, Row,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import { PageTitle } from '@/application/components/PageTitle';
import Layout from '@/application/layouts/Layout';
import LoadingIndicator from '@/shared/components/LoadingIndicator';

function DevIndex() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const toastDefault = () => toast('Default!');
  const toastSuccess = () => toast.success('Success!');
  const toastWarn = () => toast.warn('Warn!');
  const toastError = () => toast.error('Error!');
  const toastInfo = () => toast.info('Info!');
  const toastLong = () => toast('Deze titel is zo lang is dat hij op meer dan twee regels terecht komt.');
  const fireAllToasts = () => {
    toastDefault();
    toastSuccess();
    toastWarn();
    toastError();
    toastInfo();
    toastLong();
  };

  return (
    <Layout>
      <PageTitle>Documentatie</PageTitle>

      <p>
        Let op! Alle CSS staat in
        {' '}
        <code>app/javascript/styles/application.scss</code>
        , maar de componenten staan nog
        steeds in
        {' '}
        <code>app/javascript/application/..</code>
        . Ik heb
        {' '}
        <strong>géén</strong>
        {' '}
        aparte map gemaakt voor
        javascript componenten, omdat we veel javascript van de huidige applicatin folder hergebruiken.
      </p>

      <hr />

      <h2>Behulpzame links</h2>
      <CardGroup>
        <Card>
          <Card.Body>
            <Card.Title>Bootstrap 5.2</Card.Title>
            <Card.Text>
              Niet alle componenten van Bootstrap zijn ingeladen. Mis je iets? Bezoek dan
              {' '}
              <code>app/javascript/styles/application.scss</code>
              {' '}
              en voeg de component toe die je mist.
            </Card.Text>
            <Button href="https://getbootstrap.com/docs/5.2/getting-started/introduction/">Documentatie</Button>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>React Bootstrap</Card.Title>
            <Card.Text>
              React-Bootstrap is een set aan voorgemaakte componenten die we kunnen gebruiken. Bekijk de broncode
              van deze component om te zien hoe we die gebruiken!
            </Card.Text>
            <Button href="https://react-bootstrap.github.io/components/alerts">Documentatie</Button>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Testing Library</Card.Title>
            <Card.Text>
              Met deze library schrijven we javascript tests. We schrijven functionele tests op basis van de
              paginacomponent (
              <code>klik hier, verwacht dat</code>
              {' '}
              of
              {' '}
              <code>type dit, verschijnt zus</code>
              ). Je mag tests schrijven voor
              lagere componenten, maar dat is niet een eis.
            </Card.Text>
            <Button
              href="https://testing-library.com/docs/react-testing-library/example-intro#full-example"
            >
              Documentatie
            </Button>
          </Card.Body>
        </Card>
      </CardGroup>

      <hr />

      <h2>Kleuren</h2>
      <div>
        <Button className="me-2 mb-2" variant="primary">Primary</Button>
        <Button className="me-2 mb-2" variant="secondary">Secondary</Button>
        <Button className="me-2 mb-2" variant="success">Success</Button>
        <Button className="me-2 mb-2" variant="warning">Warning</Button>
        <Button className="me-2 mb-2" variant="danger">Danger</Button>
        <Button className="me-2 mb-2" variant="info">Info</Button>
        <Button className="me-2 mb-2" variant="light">Light</Button>
        <Button className="me-2 mb-2" variant="dark">Dark</Button>
        <Button className="me-2 mb-2" variant="link">Link</Button>
      </div>

      <hr />

      <h2>Font Awesome</h2>
      <p>
        Bekijk deze component om te zien hoe je Font Awesome iconen kan gebruiken. Let op: je importeert ze van de
        specifieke library (i.e. regular). Op dit moment zijn alleen regular icons als package toegevoegd.
      </p>
      <h3 className="pb-2">
        <FontAwesomeIcon className="pe-2" icon={faHouse} />
        <FontAwesomeIcon className="pe-2" icon={faAlarmPlus} />
        <FontAwesomeIcon className="pe-2" icon={faAlbumCollection} />
      </h3>
      <Button href="https://fontawesome.com/search">Zoek door alle iconen</Button>

      <hr />

      <h2>
        Toasters (React-Toastify)
        {' '}
        <a href="https://www.figma.com/file/ZHzV2c3hqXBNiO75u9toxw/SIM-Design-2022?node-id=459%3A4576">(Figma)</a>
      </h2>
      <p>
        Op dit moment zijn alleen toasters met een titel beschikbaar. Body tekst kan je dus (nog) niet opgeven.
        Toasts verdwijnen automatisch na 4 seconden. Klik
        {' '}
        <a href="https://fkhadra.github.io/react-toastify/introduction/">hier</a>
        {' '}
        om de documentatie van React-Toastify te lezen.
      </p>

      <p>
        Als je toasters wilt gebruiken, dan moet je de
        {' '}
        <code>ToastWrapper</code>
        {' '}
        component
        {' '}
        <strong>maximaal 1x</strong>
        {' '}
        op je pagina includen.
      </p>

      <Button variant="primary" className="me-2 mb-2" onClick={toastDefault}>Default</Button>
      <Button variant="success" className="me-2 mb-2" onClick={toastSuccess}>Succes</Button>
      <Button variant="warning" className="me-2 mb-2" onClick={toastWarn}>Warning</Button>
      <Button variant="danger" className="me-2 mb-2" onClick={toastError}>Error</Button>
      <Button variant="info" className="me-2 mb-2" onClick={toastInfo}>Info</Button>
      <Button variant="light" className="me-2 mb-2" onClick={toastLong}>Een toast met veel tekst</Button>
      <Button variant="dark" className="me-2 mb-2" onClick={fireAllToasts}>All toasts</Button>

      <hr />

      <h2>Spinner / loading indicator</h2>
      <p>
        Gebruik
        {' '}
        <strong>niet</strong>
        {' '}
        de bestaande Spinner component of de Spinner uit React-Bootstrap. Gebruik de LoadingIndicator component. Mis
        je een bepaalde optie? Check de
        {' '}
        <a href="https://react-bootstrap.github.io/components/spinners/">docs</a>
        {' '}
        en voeg de optie toe aan de LoadingIndicator component.
      </p>
      <LoadingIndicator size="l" />
      <LoadingIndicator />
      <LoadingIndicator size="s" />

      <hr />

      <h2>Modal</h2>

      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <hr />

      <h2>Typografie</h2>
      <Row>
        <Col lg={8}>
          <Card className="px-5 py-4">
            <Card.Body>
              <h1>TK: Stenogram tweeminutendebat armoede- en schuldenbeleid (CD d.d. 17/03) d.d. 19.04.2022</h1>
              <p>
                Op 19 april 2022 vond in de Tweede Kamer het tweeminutendebat armoede- en schuldenbeleid plaats met
                minister Schouten (APP). Het gelijknamige commissiedebat vond plaats op 17 maart 2022.
              </p>
              <p>
                Er zijn in totaal zeventien moties ingediend. In de duiding treft u de voor u relevante informatie.
                In de bijlage vindt u het
                {' '}
                <a href="https://tweedekamer.nl">stenogram</a>
                , de ingediende moties en het oordeel van de minister.
              </p>
              <p>Er zal na het meireces op 10 mei 2022 worden gestemd over de ingediende moties.</p>

              <h2>Duiding van Stakeholder Intelligence Monitor voor Wepublic</h2>
              <p>
                U ontvangt dit verslag, geschreven door een Wepublic advsieur, omdat u heeft aangegeven
                geïnteresseerd te zijn in het sociaal domein. Naast de relevante moties nemen wij u kort mee door
                inbrengen van Tweede Kamerleden die in het oog sprongen. De minister reageert in dit tweeminutendebat
                op vragen over de situatie van zelfstandigen en de effecten van de energietegemoetkoming op
                toeslagen.
              </p>

              <h3>Zelfstandigen</h3>
              <p>
                Het Tweede Kamerlid Bevers (VVD) stelt dat het van belang is om aandacht te vragen voor zzpers en de
                ondernemers. Hij vraagt minister Schouten (APP) om te reageren op het rapport van de Nationale
                Ombudsman. Ook vraagt hij de minister om de gemeenten op te roepen om hun zaken op orde te maken, want
                ook ondernemers hebben volgens Bevers recht op goede, transparante en eenduidige hulp bij schulden.
                Het Tweede Kamerlid Kat (D66) pleit voor maatwerk en te gaan werken met instrumenten die echt werken,
                zoals een saneringskrediet in plaats van een schuldregeling.
              </p>
              <small>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras porta eu orci ut sagittis. Cras
                volutpat lectus eros, eu tempor nibh euismod nec. Suspendisse dignissim scelerisque purus, quis
                volutpat
                ex sodales sed.
              </small>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default DevIndex;
