import { changeEmailPreferencesPath, emailPreferencesPath } from '@routes';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ConfirmStep from '@/application/pages/email_preferences/flow/ConfirmStep';
import FrequencyStep from '@/application/pages/email_preferences/flow/FrequencyStep';
import IssueStep from '@/application/pages/email_preferences/flow/IssueStep';
import NewUser from '@/application/pages/email_preferences/flow/NewUser';
import {
  flowReducer,
} from '@/application/pages/email_preferences/flow/reducers';
import StakeholderStep from '@/application/pages/email_preferences/flow/StakeholderStep';
import {
  transformCompanyIssuesFlow, transformForSubmissionFlow,
  transformStakeholderCategoriesFlow,
} from '@/application/pages/email_preferences/flow/transformers';
import useEmailScreen from '@/application/pages/email_preferences/hooks/useEmailScreen';
import { useAxios } from '@/shared/hooks/useApi';
import {
  CompanyWithIssuePreferences,
  EmailPreferenceFrequency,
  StakeholderCategoryPreferences,
} from '@/shared/types/emailPreferences';

export interface Props {
  onboarding: boolean
  frequencies: EmailPreferenceFrequency
  companyIssues: CompanyWithIssuePreferences[]
  stakeholderCategories: StakeholderCategoryPreferences[]
}

function EmailPreferencesFlowWrapper({
  onboarding, frequencies, stakeholderCategories, companyIssues,
}: Props) {
  const { t } = useTranslation();

  const defaultScreen = (onboarding) ? 'NewUser' : 'IssueStep';
  const {
    screen, toFrequencyStep, toIssueStep, toStakeholderStep, toConfirmStep,
  } = useEmailScreen(defaultScreen);

  const [issuesEnabled, issueDispatch] = useReducer(
    flowReducer,
    companyIssues.reduce(transformCompanyIssuesFlow, {}),
  );
  const [categoriesEnabled, categoryDispatch] = useReducer(
    flowReducer,
    stakeholderCategories.reduce(transformStakeholderCategoriesFlow, {}),
  );

  // eslint-disable-next-line no-empty-pattern
  const [{}, submit] = useAxios({
    url: changeEmailPreferencesPath(),
    method: 'POST',
  }, { manual: true });

  const submitHandler = () => {
    const issues = transformForSubmissionFlow(issuesEnabled);
    const categories = transformForSubmissionFlow(categoriesEnabled);
    submit({
      data: {
        user: {
          included_category_ids: categories,
          included_list_ids: issues,
        },
      },
    }).then(() => {
      toast.success(t('email_preferences.saved'));
      setTimeout(() => {
        window.location.href = emailPreferencesPath();
      }, 3000);
    }).catch(() => {
      toast.error(t('errors.unknown'));
    });
  };

  if (screen === 'FrequencyStep') {
    return (
      <FrequencyStep
        toIssueStep={toIssueStep}
        frequencies={frequencies}
      />
    );
  } if (screen === 'IssueStep') {
    return (
      <IssueStep
        toFrequencyStep={toFrequencyStep}
        toStakeholderStep={toStakeholderStep}
        companyIssues={companyIssues}
        issuesEnabled={issuesEnabled}
        dispatch={issueDispatch}
      />
    );
  } if (screen === 'StakeholderStep') {
    return (
      <StakeholderStep
        toIssueStep={toIssueStep}
        toConfirmStep={toConfirmStep}
        stakeholderCategories={stakeholderCategories}
        categoriesEnabled={categoriesEnabled}
        dispatch={categoryDispatch}
      />
    );
  } if (screen === 'ConfirmStep') {
    return (
      <ConfirmStep
        toIssueStep={toIssueStep}
        toStakeholderStep={toStakeholderStep}
        categoriesEnabled={categoriesEnabled}
        stakeholderCategories={stakeholderCategories}
        issuesEnabled={issuesEnabled}
        companyIssues={companyIssues}
        submitHandler={submitHandler}
      />
    );
  }

  return <NewUser nextStep={toFrequencyStep} />;
}

export default EmailPreferencesFlowWrapper;
