import React from 'react';

import { IssuePill } from '@/application/components/IssuePill';
import { Issue } from '@/shared/types/models';

export function IssuePills({ issues }: { issues: Issue[] }) {
  return (
    <div className="d-inline-flex flex-wrap justify-content-start gap-2">
      {issues.map((issue) => <IssuePill id={issue.id} key={issue.id}>{issue.name}</IssuePill>)}
    </div>
  );
}

export default IssuePills;
