// jest.mock('react-i18next', () => ({
//   initReactI18next: { type: '3rdParty', init: jest.fn() },
//   useTranslation: () => ({ t: (key: string) => key }),
//   I18nextProvider: ({ children }: any) => children,
// }));

const reactI18Next: any = jest.createMockFromModule('react-i18next');

reactI18Next.useTranslation = () => ({
  t: (str: string) => str,
  i18n: {
    changeLanguage: () => new Promise(() => {}),
  },
});

reactI18Next.I18nextProvider = ({ children }: any) => children;
module.exports = reactI18Next;
export default {};
