import {
  faAngleDown,
  faAngleRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, useState } from 'react';
import {
  Col, Row,
} from 'react-bootstrap';

import EmailPreferencesCheckbox from '@/application/components/EmailPreferencesCheckbox';
import IssueDescription from '@/application/pages/email_preferences/components/IssueDescription';
import { IssuePreference } from '@/shared/types/emailPreferences';

interface Props {
  dispatch: Dispatch<any>
  dailyEnabled: boolean
  weeklyEnabled: boolean
}

function CompanyIssueRow({
  id, name, description, preferences, dispatch, dailyEnabled, weeklyEnabled,
}: Props & IssuePreference) {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  return (
    <>
      <Row className="d-flex my-2">
        <Col xl={9} md={6} xs={3}>
          {description.length === 0 && <span className="small fw-medium">{name}</span>}

          {description.length > 0
            && (
              <div
                role="button"
                onClick={toggleShow}
                onKeyPress={toggleShow}
                className="cursor-pointer d-flex align-items-center justify-content-between"
                tabIndex={0}
              >
                <span className="small fw-medium">{name}</span>
                <FontAwesomeIcon icon={show ? faAngleDown : faAngleRight} />
              </div>
            )}
        </Col>
        <Col xl={1} md={2} xs={3} className="d-flex align-items-center justify-content-center">
          <EmailPreferencesCheckbox
            id={id}
            label={`Direct ${name}`}
            schedule="direct"
            state={preferences.direct}
            dispatch={dispatch}
          />
        </Col>
        <Col xl={1} md={2} xs={3} className="d-flex align-items-center justify-content-center">
          {dailyEnabled && (
            <EmailPreferencesCheckbox
              id={id}
              label={`daily ${name}`}
              schedule="daily"
              state={preferences.daily}
              dispatch={dispatch}
            />
          )}
        </Col>
        <Col xl={1} md={2} xs={3} className="d-flex align-items-center justify-content-center">
          {weeklyEnabled && (
            <EmailPreferencesCheckbox
              id={id}
              label={`weekly ${name}`}
              schedule="weekly"
              state={preferences.weekly}
              dispatch={dispatch}
            />
          )}
        </Col>
      </Row>
      {description.length > 0 && <IssueDescription text={description} show={show} marginStart="" /> }
    </>
  );
}

export default CompanyIssueRow;
