import { useState } from 'react';

function useStoreFeedback(type: string, id: string | number) {
  const storageKey = () => `feedback-${type}-${id}`;

  const [value, setValue] = useState(() => {
    if (typeof window === 'undefined') {
      return '';
    }

    return window.localStorage.getItem(storageKey());
  });

  const storeFeedback = (grade: string | number) => {
    const stringGrade = String(grade);
    window.localStorage.setItem(storageKey(), stringGrade);
    setValue(stringGrade);
  };

  return [value, storeFeedback] as const;
}

export default useStoreFeedback;
