import { faAt, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card } from 'react-bootstrap';

import { Agency } from '@/shared/types/models';

function AgencyCard({
  name, email, phoneNumber,
}: Agency) {
  return (
    <Card className="advisor-card">
      <Card.Body>
        <div className="d-flex flex-column">
          <div><strong>{name}</strong></div>
          <div className="pt-1">
            <FontAwesomeIcon className="pe-2 text-neutral-70" icon={faAt} />
            <a href={`mailto:${email}`} className="ps-1">{email}</a>
          </div>

          {phoneNumber
              && (
                <div className="pt-1">
                  <FontAwesomeIcon className="pe-2 text-neutral-70" icon={faPhone} />
                  <a href={`tel:${phoneNumber}`} className="ps-1">{phoneNumber}</a>
                </div>
              )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default AgencyCard;
