import React from 'react';
import { toast, ToastContainer } from 'react-toastify';

import ToastIcon from '@/shared/components/ToastIcon';

type Props = {
  notice?: string
  alert?: string
};

function ToastWrapper({ notice, alert }: Props) {
  if (notice) {
    toast(notice);
  }

  if (alert) {
    toast.error(alert);
  }

  return (
    <ToastContainer
      position="bottom-right"
      autoClose={4000}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      icon={<ToastIcon type="" />}
    />
  );
}

export default ToastWrapper;
