import { organisationAdminApiUsersPath, organisationAdminUserPath } from '@routes';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import BooleanIndicator from '@/application/components/BooleanIndicator';
import Layout from '@/application/layouts/Layout';
import LoadingIndicator from '@/shared/components/LoadingIndicator';
import { useAxios } from '@/shared/hooks/useApi';
import { User } from '@/shared/types/models';

function TableRow({
  id, full_name, email, active, otp, role,
}: User) {
  return (
    <tr>
      <td>
        <a href={organisationAdminUserPath(id)}>{email}</a>
      </td>
      <td>{full_name}</td>
      <td>{role}</td>
      <td><BooleanIndicator boolean={active} /></td>
      <td><BooleanIndicator boolean={otp} /></td>
    </tr>
  );
}

function OrganisationAdminIndex() {
  const { t } = useTranslation();
  const [{ loading, error, response }] = useAxios({
    url: organisationAdminApiUsersPath(),
  });

  let content;
  if (loading) {
    content = <LoadingIndicator />;
  } else if (error) {
    content = <p>{t('errors.unknown')}</p>;
  } else {
    const { data } = response.data;
    content = (
      <Table className="mb-0" hover responsive>
        <thead>
          <tr>
            <th>{t('activerecord.attributes.user.email')}</th>
            <th>{t('column.name')}</th>
            <th>{t('views.security.role')}</th>
            <th>{t('activerecord.attributes.user.active')}</th>
            <th>{t('otp.title')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((user: User) => (
            <TableRow key={user.id} {...user} />
          ))}
        </tbody>

      </Table>
    );
  }

  return (<Layout>{content}</Layout>);
}

export default OrganisationAdminIndex;
