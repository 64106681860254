import {
  faShareNodes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { articleSharesPath } from '@routes';
import React, { HTMLAttributes } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAxios } from '@/shared/hooks/useApi';

interface ShareButtonProps {
  articleId: string | number
  articleTitle: string
  label?: JSX.Element
  toggleBsPrefix?: string
}

function ShareButton({
  articleId, articleTitle, label = null, toggleBsPrefix = 'dropdown-toggle', ...props
}: ShareButtonProps & HTMLAttributes<HTMLButtonElement>) {
  const { t } = useTranslation();

  // eslint-disable-next-line no-empty-pattern
  const [{}, postForm] = useAxios({
    url: articleSharesPath(),
    method: 'POST',
  }, { manual: true });

  const shareThroughEmail = (url) => {
    const subject = `${t('share.subject')} [${articleTitle}]`;
    const body = `${t('share.body')}: ${url}`;
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  const shareThroughTwitter = (url) => {
    const subject = `${t('share.subject')}`;
    window.open(
      `https://twitter.com/intent/tweet?text=${subject}&url=${url}`,
      '_blank',
    );
  };

  const shareThroughWhatsapp = (url) => {
    const text = `${t('share.subject')} ${t('share.body')}: ${url}`;
    window.open(
      `https://api.whatsapp.com/send?text=${text}`,
      '_blank',
    );
  };

  const shareThroughLink = (url) => {
    navigator.clipboard.writeText(url);
    toast.success(t('share.copied_to_clipboard'));
  };

  const onSelectHandler = (eventKey) => {
    postForm({ data: { article_id: articleId } })
      .then((res) => {
        const url = res.data.share_url;
        switch (eventKey) {
          case 'email':
            shareThroughEmail(url);
            break;
          case 'twitter':
            shareThroughTwitter(url);
            break;
          case 'whatsapp':
            shareThroughWhatsapp(url);
            break;
          default:
            shareThroughLink(url);
        }
      })
      .catch(() => {
        toast.error(t('errors.unknown'));
      });
  };

  const defaultLabel = (
    <>
      <FontAwesomeIcon className="pe-2" icon={faShareNodes} />
      {t('title.share')}
    </>
  );

  return (
    <Dropdown onSelect={onSelectHandler}>
      <Dropdown.Toggle
        variant="outline-light"
        size="sm"
        bsPrefix={toggleBsPrefix}
        {...props}
      >
        {label || defaultLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="email">{t('share.email')}</Dropdown.Item>
        <Dropdown.Item eventKey="twitter">{t('share.twitter')}</Dropdown.Item>
        <Dropdown.Item eventKey="whatsapp">{t('share.whatsapp')}</Dropdown.Item>
        <Dropdown.Item eventKey="link">{t('share.link')}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ShareButton;
