export function safeBase64Encode(str: string) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    (match, p1) => String.fromCharCode(Number(`0x${p1}`)),
  ));
}

export function safeBase64Decode(str: string) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map((c) =>
    `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
}
