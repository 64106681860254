import React from 'react';

import { withLayout } from '@/application/layouts/Layout';
import AgendaFeedDeleteButton from '@/application/pages/agenda_feeds/components/AgendaFeedDeleteButton';
import AgendaFeedEditButton from '@/application/pages/agenda_feeds/components/AgendaFeedEditButton';
import { AgendaFeed } from '@/shared/types/models';

type Props = {
  id: AgendaFeed['id']
  title: AgendaFeed['title']
};

function AgendaFeedActionButtons({ id, title }: Props) {
  return (
    <>
      <div className="d-inline-block me-2">
        <AgendaFeedEditButton id={id} />
      </div>
      <AgendaFeedDeleteButton id={id} title={title} />
    </>
  );
}

export default withLayout(AgendaFeedActionButtons);
