import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiBookmarkFolderPath } from '@routes';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import DeleteModal from '@/shared/components/DeleteModal';
import { client } from '@/shared/hooks/useApi';
import { BookmarkFolder } from '@/shared/types/models';

const deleteBookmarkFolderRequest = (id) => client.delete(apiBookmarkFolderPath(id));

type DeleteBookmarkButtonProps = {
  folder: BookmarkFolder
  callbackHandler?: Function;
};

function DeleteBookmarkButton({ folder, callbackHandler }: DeleteBookmarkButtonProps) {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const deleteBookmarkFolder = () => {
    deleteBookmarkFolderRequest(folder.id)
      .then(() => {
        if (typeof (callbackHandler) === typeof (Function)) {
          callbackHandler();
        }
        toast.success(t('bookmarks.folder.deleted'));
      })
      .catch(() => {
        toast.error(t('bookmarks.folder.error'));
      });
    setShowModal(false);
  };

  return (
    <>
      <Dropdown.Item onClick={() => setShowModal(true)} className="text-danger-shade-1 ">
        <FontAwesomeIcon icon={faTrashCan} className="pe-2 small" />
        {t('button.delete')}
      </Dropdown.Item>
      <DeleteModal
        body={t('delete_modal.are_you_sure', { name: folder.name })}
        show={showModal}
        closeHandler={() => setShowModal(false)}
        confirmHandler={deleteBookmarkFolder}
      />

    </>
  );
}

export default DeleteBookmarkButton;
