import { agendasPath, apiAgendaItemsPath } from '@routes';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AgendaItemIssues from '@/application/pages/agenda/components/AgendaItemIssues';
import AgendaItemMeta from '@/application/pages/agenda/components/AgendaItemMeta';
import AgendaItemModal from '@/application/pages/agenda/components/AgendaItemModal';
import DateTimeLocalized from '@/shared/components/DateTimeLocalized';
import { Important } from '@/shared/components/Important';
import LoadingIndicator from '@/shared/components/LoadingIndicator';
import { useApiCollection } from '@/shared/hooks/useApi';
import { AgendaItem, AgendaItemCollection } from '@/shared/types/models';

function AgendaFeedItem({ item }: { item: AgendaItem }) {
  const [showModal, setShowModal] = useState(false);

  const important = item.agenda_item_companies.some((company) => company.important);

  return (
    <div className="list-group-item py-3" role="listitem">
      <div
        role="button"
        onClick={() => setShowModal(true)}
        onKeyPress={() => setShowModal(true)}
        tabIndex={0}
      >
        <h3 className="mb-2">
          {important ? <Important /> : ''}
          {item.title}
        </h3>

        <div className="mb-2">
          <AgendaItemMeta
            start_time={item.start_time}
            end_time={item.end_time}
            agenda_category={item.agenda_category}
            textSize="small"
          />
        </div>

        <AgendaItemIssues issues={item.issues} maxIssues={2} />
      </div>

      <AgendaItemModal
        showModal={showModal}
        closeHandler={() => setShowModal(false)}
        {...item}
      />
    </div>
  );
}

function AgendaFeed() {
  const { t } = useTranslation();

  const [{ items }, { loading }] = useApiCollection({
    url: `${apiAgendaItemsPath()}`,
  });

  return (
    <Card>
      <Card.Body className="list-group list-group-flush px-0">
        <div className="px-3">
          <h3 className="mb-0">{t('views.agendas.this_week.title')}</h3>
        </div>

        {!loading && items.length > 0 && (
          items.map((collection: AgendaItemCollection) => (
            <div key={collection.date}>
              <div className="px-3 text-primary small text-capitalize mt-4 mb-2">
                <DateTimeLocalized format="dddd D MMMM YYYY">
                  {collection.date}
                </DateTimeLocalized>
              </div>

              {collection.events.map((item) => (
                <AgendaFeedItem key={item.id} item={item} />
              ))}
            </div>
          ))
        )}

      </Card.Body>
      <Card.Body className="pt-0">
        {loading && <LoadingIndicator />}
        {!loading && items.length === 0 && (
        <div role="alert">
          <p>{t('views.agendas.this_week.none_exist')}</p>
          <Button href={agendasPath()}>{t('views.agendas.this_week.button')}</Button>
        </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default AgendaFeed;
