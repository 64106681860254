import { advancedEmailPreferencesPath, flowEmailPreferencesPath } from '@routes';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Layout from '@/application/layouts/Layout';
import ToggleFrequencies from '@/application/pages/email_preferences/components/ToggleFrequencies';
import LineDivider from '@/shared/components/LineDivider';
import { EmailPreferenceFrequency } from '@/shared/types/emailPreferences';
import { User } from '@/shared/types/models';

function IndexNavigation() {
  const { t } = useTranslation();
  return (
    <>
      <Button variant="outline-light" size="sm" className="me-2" href={flowEmailPreferencesPath()}>
        {t('email_preferences.buttons.restart_flow')}
      </Button>
      <Button variant="outline-light" size="sm" href={advancedEmailPreferencesPath()}>
        {t('email_preferences.buttons.to_advanced_settings')}
      </Button>
    </>
  );
}

interface Props {
  frequencies: EmailPreferenceFrequency
  user: User
}

function EmailPreferencesIndex({ frequencies, user }: Props) {
  return (
    <Layout>
      <Card>
        <Card.Body className="p-5">
          <ToggleFrequencies
            direct={frequencies.direct}
            daily={frequencies.daily}
            weekly={frequencies.weekly}
            receive_empty_weekly={user.receive_empty_weekly}
          />

          <LineDivider />

          <IndexNavigation />
        </Card.Body>
      </Card>
    </Layout>
  );
}

export default EmailPreferencesIndex;
