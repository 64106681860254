import { articlesPath } from '@routes';
import React, { ReactNode } from 'react';

import { Ellipsis } from '@/application/components/Ellipsis';
import { Issue } from '@/shared/types/models';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export function AgendaIssuePill({ children: name, ...props }: { children: ReactNode }) {
  const shortName = name.length > 20 ? `${name.substring(0, 20)}...` : name;

  const pill = <div className="small">
    <div className="rounded-3 bg-secondary text-white px-2 text-nowrap" {...props}>
      {shortName}
    </div>
  </div>;

  if (shortName === name) {
    return (pill);
  }

  const renderTooltip = (props) => <Tooltip {...props}>{name}</Tooltip>;

  return (
    <OverlayTrigger overlay={renderTooltip}>
      {pill}
    </OverlayTrigger>
  );
}

export function IssuePill({ id, children, ...props }: { children: string | ReactNode } & Pick<Issue, 'id'>) {
  return (
    <a href={articlesPath({ issue_id: id })} className="text-neutral-100 small" {...props}>
      <div className="rounded-3 bg-neutral-10 px-2 text-nowrap" title={children}>
        <Ellipsis length={26}>{children}</Ellipsis>
      </div>
    </a>
  );
}
