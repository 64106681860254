/* eslint-disable max-len */
import React from 'react';

interface Props {
  locale: string
}

export function DutchFlag() {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_343_2724)">
        <path d="M0 0H21.3333V16H0V0Z" fill="#21468B" />
        <path d="M0 0H21.3333V10.6667H0V0Z" fill="white" />
        <path d="M0 0H21.3333V5.33333H0V0Z" fill="#AE1C28" />
      </g>
      <defs>
        <clipPath id="clip0_343_2724">
          <path d="M0 2C0 0.895431 0.895431 0 2 0H19.3333C20.4379 0 21.3333 0.895431 21.3333 2V14C21.3333 15.1046 20.4379 16 19.3333 16H2C0.895433 16 0 15.1046 0 14V2Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function EnglishFlag() {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_343_2730)">
        <path d="M0 0H21.3333V16H0V0Z" fill="#012169" />
        <path d="M2.5 0L10.6333 6.03333L18.7333 0H21.3333V2.06667L13.3333 8.03333L21.3333 13.9667V16H18.6667L10.6667 10.0333L2.7 16H0V14L7.96667 8.06667L0 2.13333V0H2.5Z" fill="white" />
        <path d="M14.1333 9.36667L21.3333 14.6667V16L12.3 9.36667H14.1333ZM8 10.0333L8.2 11.2L1.8 16H0L8 10.0333ZM21.3333 0V0.1L13.0333 6.36667L13.1 4.9L19.6667 0H21.3333ZM0 0L7.96667 5.86667H5.96667L0 1.4V0Z" fill="#C8102E" />
        <path d="M8.03333 0V16H13.3667V0H8.03333ZM0 5.33333V10.6667H21.3333V5.33333H0Z" fill="white" />
        <path d="M0 6.43333V9.63333H21.3333V6.43333H0ZM9.1 0V16H12.3V0H9.1Z" fill="#C8102E" />
      </g>
      <defs>
        <clipPath id="clip0_343_2730">
          <path d="M0 2C0 0.895431 0.895431 0 2 0H19.3333C20.4379 0 21.3333 0.895431 21.3333 2V14C21.3333 15.1046 20.4379 16 19.3333 16H2C0.895433 16 0 15.1046 0 14V2Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function FranceFlag() {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_343_2730)">
        <path d="M0 0H21.3333V16H0V0Z" fill="#012169" />
        <path d="M2.5 0L10.6333 6.03333L18.7333 0H21.3333V2.06667L13.3333 8.03333L21.3333 13.9667V16H18.6667L10.6667 10.0333L2.7 16H0V14L7.96667 8.06667L0 2.13333V0H2.5Z" fill="white" />
        <path d="M14.1333 9.36667L21.3333 14.6667V16L12.3 9.36667H14.1333ZM8 10.0333L8.2 11.2L1.8 16H0L8 10.0333ZM21.3333 0V0.1L13.0333 6.36667L13.1 4.9L19.6667 0H21.3333ZM0 0L7.96667 5.86667H5.96667L0 1.4V0Z" fill="#C8102E" />
        <path d="M8.03333 0V16H13.3667V0H8.03333ZM0 5.33333V10.6667H21.3333V5.33333H0Z" fill="white" />
        <path d="M0 6.43333V9.63333H21.3333V6.43333H0ZM9.1 0V16H12.3V0H9.1Z" fill="#C8102E" />
      </g>
      <defs>
        <clipPath id="clip0_343_2730">
          <path d="M0 2C0 0.895431 0.895431 0 2 0H19.3333C20.4379 0 21.3333 0.895431 21.3333 2V14C21.3333 15.1046 20.4379 16 19.3333 16H2C0.895433 16 0 15.1046 0 14V2Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CountryFlag({ locale }: Props) {
  return (locale === 'nl') ? <DutchFlag /> : <EnglishFlag />;
}
