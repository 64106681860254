import React, { Dispatch } from 'react';

interface Props {
  id: string
  schedule: 'agenda'
  state: boolean
  label: string
  dispatch: Dispatch<any>
}

function AgendaPreferencesCheckbox({
  id, schedule, state, label, dispatch,
}: Props) {
  const handleStateChange = () => {
    dispatch({
      type: 'toggle', id, schedule, state,
    });
  };

  const labelId = `${id}-schedule`;

  return (
    <div className="d-flex align-items-center">
      <input
        id={labelId}
        className="form-check-input form-check-input-lg me-3 mt-0"
        type="checkbox"
        checked={state}
        onChange={handleStateChange}
      />
      <label htmlFor={labelId}>
        {label}
      </label>
    </div>
  );
}

export default AgendaPreferencesCheckbox;
