import React from 'react';

import PrintButton from '@/application/components/PrintButton';
import Layout from '@/application/layouts/Layout';
import BookmarkButton from '@/application/pages/articles/components/BookmarkButton';
import ShareButton from '@/application/pages/articles/components/ShareButton';
import { BookmarkFolder } from '@/shared/types/models';

interface Props {
  articleId: string
  articleTitle: string
  bookmarkFolders: BookmarkFolder[]
}

function ArticleActionButtons({ articleId, articleTitle, bookmarkFolders }: Props) {
  return (
    <Layout>
      <div className="d-flex justify-content-end">
        <ShareButton articleId={articleId} articleTitle={articleTitle} />
        <BookmarkButton articleId={articleId} bookmarkFolders={bookmarkFolders} />
        <PrintButton size="sm" />
      </div>
    </Layout>
  );
}

export default ArticleActionButtons;
