export const transformCompanyIssuesAdvanced = (accumulator, company) => {
  company.issues.forEach((issue) => {
    accumulator[issue.id] = issue.preferences;
  });
  return accumulator;
};

export const transformIssuesForSubmissionAdvanced = (issues) => {
  const direct = [];
  const daily = [];
  const weekly = [];
  const agenda = [];
  Object.keys(issues).forEach((id) => {
    const issue = issues[id];
    if (issue.direct === true) { direct.push(id); }
    if (issue.daily === true) { daily.push(id); }
    if (issue.weekly === true) { weekly.push(id); }
    if (issue.agenda === true) { agenda.push(id); }
  });
  return {
    direct, daily, weekly, agenda
  };
};

export const transformStakeholderCategoriesAdvanced = (accumulator, category) => {
  accumulator[category.id] = category.preferences;
  return accumulator;
};

export const transformStakeholderCategoriesForSubmissionAdvanced = (stakeholderCategories) => {
  const direct = [];
  const daily = [];
  const weekly = [];
  Object.keys(stakeholderCategories).forEach((id) => {
    const category = stakeholderCategories[id];
    if (category.direct === true) { direct.push(id); }
    if (category.daily === true) { daily.push(id); }
    if (category.weekly === true) { weekly.push(id); }
  });
  return {
    direct, daily, weekly,
  };
};
