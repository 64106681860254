import { faShare, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import DeleteBookmarkFolderButton from '@/application/pages/bookmarks/components/DeleteBookmarkFolderButton';
import DeleteSharedBookmarkFolderButton
  from '@/application/pages/bookmarks/components/DeleteSharedBookmarkFolderButton';
import EditBookmarkFolderButton from '@/application/pages/bookmarks/components/EditBookmarkFolderButton';
import { BookmarkFolder } from '@/shared/types/models';

const CustomToggle = forwardRef<any, ButtonHTMLAttributes<any>>(({ onClick }, ref) => (
  <button
    className="btn btn-outline-light dossier-edit-button"
    ref={ref}
    type="button"
    onClick={onClick}
  >
    <i className="fa-regular fa-ellipsis" />
  </button>
));

type BookmarkNavItemProps = {
  folder: BookmarkFolder
  refreshBookmarks: () => void
};

function BookmarkNavItem({
  folder, refreshBookmarks,
}: BookmarkNavItemProps) {
  const { t } = useTranslation();

  const shareThroughLink = () => {
    navigator.clipboard.writeText(folder.share_url);
    toast.success(t('share.copied_to_clipboard'));
  };

  const shareThroughEmail = () => {
    const subject = t('bookmarks.folder.share.through_email.subject', { folder_name: folder.name });
    const body = t(
      'bookmarks.folder.share.through_email.body',
      { user_name: '', folder_name: folder.name, invite_url: folder.share_url },
    );
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  return (
    <Nav.Link
      eventKey={folder.id}
      className="d-flex align-items-center justify-content-between cursor-pointer soh-container"
      as="div"
    >
      <span className="text-break me-3">
        {folder.name}
      </span>

      <div className="d-flex align-items-center text-nowrap">
        {folder.shared && (
        <span className="dossier-badge text-dark me-2">
          <FontAwesomeIcon icon={faUser} className="pe-2 small" />
          {folder.shared_by}
        </span>
        )}
        <span className="dossier-badge text-dark me-2">{folder.bookmarks.length}</span>

        {!folder.shared && (
          <Dropdown className="soh-item">
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu>
              <EditBookmarkFolderButton folder={folder} callbackHandler={refreshBookmarks} />
              <DeleteBookmarkFolderButton folder={folder} callbackHandler={refreshBookmarks} />
              <Dropdown.Divider />
              <Dropdown.Header>{t('title.share')}</Dropdown.Header>

              <Dropdown.Item eventKey="shareLink" onClick={shareThroughLink}>
                <FontAwesomeIcon icon={faShare} className="pe-2 small" />
                {t('share.link')}
              </Dropdown.Item>
              <Dropdown.Item eventKey="shareEmail" onClick={shareThroughEmail}>
                <FontAwesomeIcon icon={faShare} className="pe-2 small" />
                {t('share.email')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {folder.shared && (
          <Dropdown className="soh-item">
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu>
              <DeleteSharedBookmarkFolderButton folder={folder} callbackHandler={refreshBookmarks} />
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </Nav.Link>
  );
}

export default BookmarkNavItem;
