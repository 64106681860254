import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export function FetchMoreButton({ onClick, children }: { onClick: () => void, children?: ReactNode }) {
  const { t } = useTranslation();
  return (
    <button type="button" className="btn btn-primary" onClick={onClick}>
      {children || t('message.show_more')}
    </button>
  );
}
