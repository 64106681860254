import React, { Dispatch } from 'react';
import {
  Col, Row,
} from 'react-bootstrap';

import EmailPreferencesCheckbox from '@/application/components/EmailPreferencesCheckbox';

interface Props {
  id: string
  name: string
  preferences: {
    direct: boolean
    daily: boolean
    weekly: boolean
  }
  dispatch: Dispatch<any>
}

function StakeholderCategoryRow({
  id, name, preferences, dispatch,
}: Props) {
  return (
    <Row className="d-flex my-2">
      <Col xl={9} md={6} xs={3}>
        <span className="small fw-medium">{name}</span>
      </Col>
      <Col xl={1} md={2} xs={3} className="d-flex align-items-center justify-content-center">
        <EmailPreferencesCheckbox
          id={id}
          label={`Direct ${name}`}
          schedule="direct"
          state={preferences.direct}
          dispatch={dispatch}
        />
      </Col>
      <Col xl={1} md={2} xs={3} className="d-flex align-items-center justify-content-center">
        <EmailPreferencesCheckbox
          id={id}
          label={`daily ${name}`}
          schedule="daily"
          state={preferences.daily}
          dispatch={dispatch}
        />
      </Col>
      <Col xl={1} md={2} xs={3} className="d-flex align-items-center justify-content-center">
        <EmailPreferencesCheckbox
          id={id}
          label={`weekly ${name}`}
          schedule="weekly"
          state={preferences.weekly}
          dispatch={dispatch}
        />
      </Col>
    </Row>
  );
}

export default StakeholderCategoryRow;
