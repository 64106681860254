import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import EmailPreferencesSwitch from '@/application/components/EmailPreferencesSwitch';
import AdvancedSettingsLink from '@/application/pages/email_preferences/components/AdvancedSettingsLink';
import IssueDescription from '@/application/pages/email_preferences/components/IssueDescription';
import NoEmailWarning from '@/application/pages/email_preferences/components/NoEmailWarning';
import LineDivider from '@/shared/components/LineDivider';
import {
  CompanyWithIssuePreferences,
} from '@/shared/types/emailPreferences';

interface IssueRowProps {
  id: string
  name: string
  description: string
  enabled: boolean
  dispatch: Dispatch<any>
}

function IssueRow({
  id, name, description, enabled, dispatch,
}: IssueRowProps) {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  return (
    <>
      <div className="d-flex mb-2">
        <div className="flex-fill">
          <EmailPreferencesSwitch
            id={id}
            label={name}
            state={enabled}
            onChange={(state: boolean) => dispatch({ type: 'toggle', id, enabled: state })}
          />
        </div>
        {description.length > 0
            && (
            <div
              role="button"
              onClick={toggleShow}
              onKeyPress={toggleShow}
              className="cursor-pointer flex-grow-1 d-flex justify-content-end align-items-center"
              tabIndex={0}
            >
              <FontAwesomeIcon className="float-end" icon={show ? faAngleDown : faAngleRight} />
            </div>
            )}
      </div>
      {description.length > 0 && <IssueDescription text={description} show={show} /> }
    </>
  );
}

interface IssueStepProps {
  toFrequencyStep: () => void
  toStakeholderStep: () => void
  companyIssues: CompanyWithIssuePreferences[]
  issuesEnabled: any
  dispatch: Dispatch<any>
}

function IssueStep({
  toFrequencyStep, toStakeholderStep, companyIssues, dispatch, issuesEnabled,
}: IssueStepProps) {
  const { t } = useTranslation();

  const numberOfIssues = (): number => Object.keys(issuesEnabled).length;
  const selected = () => Object.values(issuesEnabled).filter((value) => value === true);
  const allSelected = () => selected().length === numberOfIssues();
  const noneSelected = () => selected().length === 0;

  const selectAllHandler = () => {
    if (allSelected()) {
      dispatch({ type: 'disableAll' });
    } else {
      dispatch({ type: 'enableAll' });
    }
  };

  return (
    <>
      <div>
        <h3 className="mb-3">{t('email_preferences.issue.title')}</h3>
        <p className="text-neutral-90 mb-4">{t('email_preferences.issue.subtitle')}</p>
      </div>

      <Card>
        <Card.Body className="p-5">
          <div className="mb-4">
            <h3 className="mb-3">{t('email_preferences.issue.card_title')}</h3>

            <div className="mb-3">
              <EmailPreferencesSwitch
                id="selectAll"
                label={t('email_preferences.issue.select_all', { count: numberOfIssues() })}
                state={allSelected()}
                onChange={selectAllHandler}
              />
            </div>
          </div>

          {companyIssues.map((company) => (
            <div key={company.name}>
              <LineDivider />
              <h3 className="mb-3">{company.name}</h3>
              {company.issues.map((issue) => (
                <IssueRow
                  key={issue.id}
                  {...issue}
                  dispatch={dispatch}
                  enabled={issuesEnabled[issue.id]}
                />
              ))}
            </div>
          ))}

          {noneSelected() && (
          <>
            <LineDivider />
            <NoEmailWarning />
          </>
          )}

          <LineDivider />
          <div className="d-flex justify-content-between">
            <Button variant="outline-light" onClick={toFrequencyStep}>
              <FontAwesomeIcon className="pe-2" icon={faAngleLeft} />
              {t('email_preferences.buttons.to_frequency_step')}
            </Button>

            <Button variant="primary" onClick={toStakeholderStep}>
              {t('email_preferences.buttons.to_stakeholder_step')}
              <FontAwesomeIcon className="ps-2" icon={faAngleRight} />
            </Button>
          </div>
        </Card.Body>
      </Card>

      <AdvancedSettingsLink />
    </>
  );
}

export default IssueStep;
