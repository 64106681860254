import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function LoadingCompleted() {
  const { t } = useTranslation();
  return (
    <div className="far fa-check text-primary h2 mb-0" role="status">
      <span className="sr-only">{t('message.completed')}</span>
    </div>
  );
}
