import { agendaFeedsPath, apiAgendaFeedPath } from '@routes';
import React from 'react';

import { ResourceDeleteButton } from '@/shared/components/ResourceDeleteButton';
import { AgendaFeed } from '@/shared/types/models';

function AgendaFeedDeleteButton({ id, title }: Pick<AgendaFeed, 'id' | 'title'>) {
  return (
    <ResourceDeleteButton
      uri={apiAgendaFeedPath(id)}
      name={title}
      redirectTo={agendaFeedsPath()}
    />
  );
}

export default AgendaFeedDeleteButton;
