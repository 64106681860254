import {
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button, ButtonProps,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface SaveButtonProps {
  onClickHandler: () => void
}

function SaveButton({ onClickHandler, ...props }: SaveButtonProps & ButtonProps) {
  const { t } = useTranslation();
  return (
    <Button variant="success" onClick={onClickHandler} {...props}>
      <FontAwesomeIcon icon={faCheck} className="pe-2" />
      {t('button.save')}
    </Button>
  );
}

export default SaveButton;
