import { PropsWithChildren } from 'react';

import { useFeature } from '@/application/hooks/useFeature';

type Props = PropsWithChildren<{ name: string }>;

/**
 * Component that only renders children when the given feature is enabled
 */
export function Feature({ name, children }: Props): JSX.Element {
  const feature = useFeature(name);
  return feature ? children as JSX.Element : null;
}
