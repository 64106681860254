import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 * Taken from: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Function that fires if clicked outside ref
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default useOutsideAlerter;
