import React from 'react';
import { useTranslation } from 'react-i18next';

interface SwitchLabelProps {
  label: string
}

function SwitchLabel({ label }: SwitchLabelProps) {
  return (
    <div className="mt-1">{label}</div>
  );
}

interface SwitchLabelWithHintProps {
  label: string
  hint: string
  suggested?: boolean
}

function SwitchLabelWithHint({ label, hint, suggested }: SwitchLabelWithHintProps) {
  const { t } = useTranslation();
  return (
    <small className="lh-sm">
      <span className="d-block">
        <strong>{label}</strong>
        {suggested && (
        <span className="text-secondary fw-medium fs-very-small ms-2">
          {t('email_preferences.suggestion')}
        </span>
        )}
      </span>
      <span className="text-neutral-90">{hint}</span>
    </small>
  );
}

interface EmailPreferencesSwitchProps {
  id: string
  label: string
  hint?: string
  state?: boolean
  onChange: (boolean) => void
  suggested?: boolean
}

function EmailPreferencesSwitch({
  id, state = false, onChange, label, hint, suggested,
}: EmailPreferencesSwitchProps) {
  const handleOnChange = () => {
    onChange(!state);
  };

  let labelComponent;
  if (label && hint) {
    labelComponent = <SwitchLabelWithHint label={label} hint={hint} suggested={suggested} />;
  } else {
    labelComponent = <SwitchLabel label={label} />;
  }

  return (
    <div className="form-check form-switch">
      <input
        type="checkbox"
        id={id}
        className="form-check-input form-check-input-lg"
        checked={state}
        onChange={handleOnChange}
      />
      <label
        htmlFor={id}
        className="form-check-label ms-3"
      >
        {labelComponent}
      </label>
    </div>
  );
}

export default EmailPreferencesSwitch;
