import React from 'react';

import Layout from '@/application/layouts/Layout';

import Faq from './components/Faq';
import faqItems from './questions.json';

function FaqIndex(props) {
  return (
    <Layout>
      <Faq {...props} faqItems={faqItems} />
    </Layout>
  );
}

export default FaqIndex;
