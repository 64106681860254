import React from 'react';

import Layout from '@/application/layouts/Layout';
import AdvancedSettings from '@/application/pages/email_preferences/advanced/AdvancedSettings';
import { CompanyWithIssuePreferences, StakeholderCategoryPreferences } from '@/shared/types/emailPreferences';

interface Props {
  stakeholderCategories: StakeholderCategoryPreferences[]
  companyIssues: CompanyWithIssuePreferences[]
}

function EmailPreferencesAdvanced({ companyIssues, stakeholderCategories }: Props) {
  return (
    <Layout>
      <AdvancedSettings
        companyIssues={companyIssues}
        stakeholderCategories={stakeholderCategories}
      />
    </Layout>
  );
}

export default EmailPreferencesAdvanced;
