import React, { Dispatch } from 'react';

import LineDivider from '@/shared/components/LineDivider';
import { CompanyWithIssuePreferences, IssuePreference } from '@/shared/types/emailPreferences';

import CompanyIssueRow from './CompanyIssueRow';
import CompanyIssuesHeader from './CompanyIssuesHeader';

interface Props {
  company: CompanyWithIssuePreferences
  dispatch: Dispatch<any>
  preferenceState: any
}

function CompanyIssues({ company, preferenceState, dispatch }: Props) {
  return (
    <div className="my-4">
      <LineDivider margin="3" />
      <CompanyIssuesHeader
        name={company.name}
        dailyEnabled={company.dailyEnabled}
        weeklyEnabled={company.weeklyEnabled}
      />
      {company.issues.map((issue: IssuePreference) => (
        <CompanyIssueRow
          key={issue.id}
          {...issue}
          dailyEnabled={company.dailyEnabled}
          weeklyEnabled={company.weeklyEnabled}
          preferences={preferenceState[issue.id]}
          dispatch={dispatch}
        />
      ))}
    </div>
  );
}

export default CompanyIssues;
