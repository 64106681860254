export const stakeholderCategoryReducerAdvanced = (state, action) => {
  let newState;
  let stateForId;
  switch (action.type) {
    case 'toggle':
      stateForId = { ...state[action.id], ...{ [action.schedule]: !action.state } };
      newState = { ...state, [action.id]: stateForId };
      break;
    default:
      break;
  }
  return newState;
};

export const issueReducerAdvanced = (state, action) => {
  let newState;
  let stateForId;
  switch (action.type) {
    case 'toggle':
      stateForId = { ...state[action.id], ...{ [action.schedule]: !action.state } };
      newState = { ...state, [action.id]: stateForId };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};
