import { faAngleRight, faAt, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiCompanyAdvisorsPath, faqPath } from '@routes';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { exportedParams } from '@/application/atoms/exports';
import useKeyPressed from '@/application/hooks/useKeyPressed';
import useOutsideAlerter from '@/application/hooks/useOutsideAlerter';
import Layout from '@/application/layouts/Layout';
import { useAxios } from '@/shared/hooks/useApi';
import { User } from '@/shared/types/models';

function Advisor({
  avatar, full_name, phone_number, email,
}: User) {
  return (
    <div className="d-flex">
      <div className="advisor-avatar-sm d-none d-sm-flex overflow-hidden rounded-4 me-3">
        <Image
          fluid
          src={avatar.url}
          alt={full_name}
        />
      </div>
      <div className="d-flex flex-column">
        <div className="fw-bold">{full_name}</div>
        <div className="details-overflow text-truncate">
          <FontAwesomeIcon className="pe-2 text-neutral-70" icon={faAt} />
          <a href={`mailto:${email}`} className="ps-1 text-break">{email}</a>
        </div>

        {phone_number
          && (
            <div>
              <FontAwesomeIcon className="pe-2 text-neutral-70" icon={faPhone} />
              <a href={`tel:${phone_number}`} className="ps-1 text-break">{phone_number}</a>
            </div>
          )}
      </div>
    </div>
  );
}

function SupportCard() {
  const { t } = useTranslation();
  const {
    agencyName, agencyEmail, agencyPhoneNumber,
  } = useRecoilValue(exportedParams);

  // eslint-disable-next-line no-empty-pattern
  const [{}, getAdvisors] = useAxios({
    url: apiCompanyAdvisorsPath(),
  }, { manual: true });
  const [advisor, setAdvisor] = useState(null);

  useEffect(() => {
    getAdvisors().then(({ data }) => {
      setAdvisor(data.data[0]);
    });
  }, []);

  return (
    <div className="support-card list-group list-group-flush">
      <div className="list-group-item py-3">
        <h3 className="mb-3">{t('menu.need_help')}</h3>
        <a className="text-default d-flex justify-content-between align-items-center" href={faqPath()}>
          <span>{t('menu.faq')}</span>
          <FontAwesomeIcon className="float-end" icon={faAngleRight} />
        </a>
      </div>

      {advisor
        && (
          <div className="list-group-item py-3">
            <h3 className="mb3">{t('menu.im_here_to_help')}</h3>
            <Advisor {...advisor} />
          </div>
        )}

      <div className="list-group-item py-3">
        <div className="fw-bold">{agencyName}</div>

        <div className="pt-1 details-overflow text-truncate">
          <FontAwesomeIcon className="pe-2 text-neutral-70" icon={faAt} />
          <a href={`mailto:${agencyEmail}`} className="ps-1">{agencyEmail}</a>
        </div>

        {agencyPhoneNumber
          && (
            <div className="pt-1">
              <FontAwesomeIcon className="pe-2 text-neutral-70" icon={faPhone} />
              <a href={`tel:${agencyPhoneNumber}`} className="ps-1">{agencyPhoneNumber}</a>
            </div>
          )}
      </div>
    </div>
  );
}

function SupportButton() {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => setExpanded(!expanded);
  const close = () => setExpanded(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, close);
  useKeyPressed('Escape', close);

  return (
    <Layout>
      <div ref={wrapperRef}>
        <Button
          variant="info"
          className={`support-button ${expanded ? 'support-button-expanded' : ''}`}
          onClick={toggle}
        >
          <i className="fa-solid fa-user-headset" />
        </Button>

        {expanded && <SupportCard />}
      </div>
    </Layout>
  );
}

export default SupportButton;
