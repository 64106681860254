import { useEffect } from 'react';

/**
 * Hook that fires a function if a certain key is pressed
 */
function useKeyPressed(key, callback) {
  useEffect(() => {
    /**
     * Function that fires if clicked outside ref
     */
    function handleClickOutside(event) {
      if (event.key === key) {
        callback(event);
      }
    }
    // Bind the event listener
    document.addEventListener('keydown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', handleClickOutside);
    };
  }, []);
}

export default useKeyPressed;
