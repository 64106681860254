import React from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function StakeholderCategoryHeader() {
  const { t } = useTranslation();

  return (
    <Row>
      <Col xl={9} md={6} xs={12} />
      <Col
        xl={1}
        md={{ offset: 0, span: 2 }}
        xs={{ offset: 3, span: 3 }}
        className="d-flex align-items-center justify-content-center fw-medium"
      >
        {t('email_preferences.frequency.direct')}
      </Col>
      <Col xl={1} md={2} xs={3} className="d-flex align-items-center justify-content-center fw-medium">
        {t('email_preferences.frequency.daily_short')}
      </Col>
      <Col xl={1} md={2} xs={3} className="d-flex align-items-center justify-content-center fw-medium">
        {t('email_preferences.frequency.weekly_short')}
      </Col>
    </Row>
  );
}

export default StakeholderCategoryHeader;
