import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import SaveButton from '@/application/components/SaveButton';
import LineDivider from '@/shared/components/LineDivider';
import { CompanyWithIssuePreferences, StakeholderCategoryPreferences } from '@/shared/types/emailPreferences';

interface Props {
  toIssueStep: () => void
  toStakeholderStep: () => void
  categoriesEnabled: any
  stakeholderCategories: StakeholderCategoryPreferences[]
  issuesEnabled: any
  companyIssues: CompanyWithIssuePreferences[]
  submitHandler: () => void
}

function ConfirmBadge({ name }: { name: string }) {
  return <span className="confirm-badge me-2 mb-2">{name}</span>;
}

function ConfirmStep({
  toIssueStep,
  toStakeholderStep,
  categoriesEnabled, stakeholderCategories,
  issuesEnabled, companyIssues,
  submitHandler,
}: Props) {
  const { t } = useTranslation();

  const count = (obj): number => Object.keys(obj).length;
  const selected = (obj) => Object.values(obj).filter((value) => value === true);

  return (
    <>
      <div>
        <h3 className="mb-3">{t('email_preferences.confirm.title')}</h3>
        <p className="text-neutral-90 mb-0">{t('email_preferences.confirm.subtitle')}</p>
      </div>

      <Card className="my-4">
        <Card.Body className="p-5">
          <Row>
            <Col lg={9}>
              <div className="fw-bold">{t('email_preferences.issue.card_title')}</div>
              <div className="text-neutral-70 small mb-3">
                {`${count(selected(issuesEnabled))} 
                 ${t('email_preferences.confirm.out_of')}
                 ${count(issuesEnabled)}`}
              </div>
              <div className="mb-3">
                {companyIssues.map((company) => (
                  company.issues.map(({ id, name }) => (
                    issuesEnabled[id] && <ConfirmBadge key={id} name={name} />
                  ))
                ))}
              </div>
            </Col>
            <Col lg={3}>
              <Button variant="outline-light" className="float-lg-end" onClick={toIssueStep}>
                {t('button.edit')}
                <FontAwesomeIcon className="ps-2" icon={faPenToSquare} />
              </Button>
            </Col>
          </Row>

          <LineDivider />

          <Row>
            <Col lg={9}>
              <div className="fw-bold">{t('email_preferences.stakeholder.card_title')}</div>
              <div className="text-neutral-70 small mb-3">
                {`${count(selected(categoriesEnabled))} 
                 ${t('email_preferences.confirm.out_of')}
                 ${count(categoriesEnabled)}`}
              </div>
              <div className="mb-3">
                {stakeholderCategories.map(({ id, name }) => (
                  categoriesEnabled[id] && <ConfirmBadge key={id} name={name} />
                ))}
              </div>
            </Col>
            <Col lg={3}>
              <Button variant="outline-light" className="float-lg-end" onClick={toStakeholderStep}>
                {t('button.edit')}
                <FontAwesomeIcon className="ps-2" icon={faPenToSquare} />
              </Button>
            </Col>
          </Row>

          <LineDivider />

          <div className="d-flex justify-content-end">
            <SaveButton onClickHandler={submitHandler} />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default ConfirmStep;
