import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { usePrevious } from '@/application/hooks/usePrevious';

export function isInWindow(el: HTMLElement): boolean {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  return elemTop < window.innerHeight && elemBottom >= 0;
}

type Props = {
  /**
   * Called on scroll event if there are changes in visibility
   */
  onVisibilityChange?: (v: boolean) => void

  /**
   * Called on scroll event if component enters viewport
   */
  onVisibilityStart?: () => void

  /**
   * Called on scroll event if component leaves viewport
   */
  onVisibilityStop?: () => void

  /**
   * Called on initial component render with the current visibility
   */
  onVisibilityInitialize?: (v: boolean) => void

  /**
   * Scroller element selector to use. This is usually the body,
   * element, but applications may choose to scroll in a different
   * HTMLElement
   */
  containerSelector?: string
};

/**
 * Component that detects if it is visible within the viewport of the browser,
 * and provides callback properties to track changes in visiblity.
 */
function VisibilityDetector({
  onVisibilityInitialize = (visible: boolean) => visible,
  onVisibilityChange = () => null,
  onVisibilityStart = () => null,
  onVisibilityStop = () => null,
  containerSelector = null,
}: Props) {
  const [visible, setVisible] = useState<boolean>(null);
  const previouslyVisible = usePrevious(visible);
  const $el = useRef<HTMLDivElement>();

  // initialization
  useEffect(() => {
    if ($el.current) {
      setVisible(isInWindow($el.current));
    }
  }, [$el]);

  // track on every scroll
  useEffect(() => {
    const scroller = containerSelector ? document.querySelector(containerSelector) : document;
    if (scroller) {
      const trigger = ((element) => () => setVisible(isInWindow(element)))($el.current);
      scroller.addEventListener('scroll', trigger, { passive: true });
      return () => {
        scroller.removeEventListener('scroll', trigger);
      };
    }
    // eslint-disable-next-line no-console
    console.error(`No scroller: ${scroller}`);
  }, [containerSelector, $el]);

  useEffect(() => {
    if (visible == null || previouslyVisible === null) {
      // don't trigger anything if we're initialzing
      return;
    }
    if (visible === previouslyVisible) {
      // don't trigger if we're not transitioning states
      return;
    }
    if (onVisibilityChange) {
      onVisibilityChange(visible);
    }
    if (onVisibilityStart && visible) {
      onVisibilityStart();
    }
    if (onVisibilityStop && !visible) {
      onVisibilityStop();
    }
  }, [
    onVisibilityChange,
    onVisibilityStart,
    onVisibilityStop,
    previouslyVisible,
    visible,
  ]);

  useEffect(
    () => {
    // only trigger if we're rendering for the first time
      onVisibilityInitialize(isInWindow($el.current));
    },
    [],
  );

  return <div ref={$el} />;
}

export default VisibilityDetector;
