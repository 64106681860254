export const flowReducer = (state, action) => {
  let newState;
  switch (action.type) {
    case 'toggle':
      newState = { ...state, ...{ [action.id]: action.enabled } };
      break;
    case 'enableAll':
      newState = Object.keys(state).reduce((result, key) => ({ ...result, [key]: true }), {});
      break;
    case 'disableAll':
      newState = Object.keys(state).reduce((result, key) => ({ ...result, [key]: false }), {});
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};
