import React from 'react';

import Layout from '@/application/layouts/Layout';
import EmailPreferencesFlowWrapper,
{ Props } from '@/application/pages/email_preferences/flow/EmailPreferencesFlowWrapper';

function EmailPreferencesFlow({
  onboarding, frequencies, companyIssues, stakeholderCategories,
}: Props) {
  return (
    <Layout>
      <EmailPreferencesFlowWrapper
        onboarding={onboarding}
        frequencies={frequencies}
        companyIssues={companyIssues}
        stakeholderCategories={stakeholderCategories}
      />
    </Layout>
  );
}

export default EmailPreferencesFlow;
