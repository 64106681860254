import {
  faLandmark,
  faUserLargeSlash,
  faUsers,
  faUserTag,
} from '@fortawesome/pro-regular-svg-icons';
import {
  apiMinistriesPath,
  apiPeoplePath,
  apiPoliticalPartiesPath,
  apiPositionsPath,
} from '@routes';
import * as React from 'react';
import { Dispatch, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FetchMoreButton } from '@/application/components/FetchMoreButton';
import {
  exportFilters,
  Filter,
  FilterAction, FilterOption, FilterOptionProps, FilterPill,
  filterReducerWithDefault, Filters, ResetFiltersButton,
  useFilter,
} from '@/application/components/Filter';
import NothingFound from '@/application/components/NothingFound';
import { PageTitle } from '@/application/components/PageTitle';
import { SearchBar } from '@/application/components/SearchBar';
import VisibilityDetector from '@/application/components/VisibilityDetector';
import LoadingIndicator from '@/shared/components/LoadingIndicator';
import { useApiCollection } from '@/shared/hooks/useApi';
import { useCursor, useNextCursor } from '@/shared/hooks/useCursor';
import {
  Ministry,
  Person,
  PoliticalParty,
  Position,
} from '@/shared/types/models';
import { toQueryString } from '@/shared/utilities/querystring';

import PersonCard from './PersonCard';

type PersonFilterTypes = Ministry | PoliticalParty | Position;
type PersonFilters = Filters<PersonFilterTypes>;

const defaultPersonFilter = () => ({
  Position: {},
  Ministry: {},
  PoliticalParty: {},
});

const personFilterReducer = filterReducerWithDefault(defaultPersonFilter);

type PersonListOptionsProps = {
  dispatch: Dispatch<FilterAction<PersonFilterTypes>>
  filters: PersonFilters,
};

function PersonListOptions({ dispatch, filters }: PersonListOptionsProps) {
  const { t } = useTranslation();
  return (
    <div className="flex-fill">
      <div className="d-inline-flex flex-wrap gap-2">
        <Filter
          label={t('filters.positions')}
          url={apiPositionsPath()}
          renderOption={({ id, name, ...props }: Position & FilterOptionProps) =>
            <FilterOption key={id} icon={faUserTag} label={name} {...props} />}
          onSelectItem={(item: Position) => dispatch({ type: 'select', itemType: 'Position', item })}
          selected={filters.Position}
        />
        <Filter
          label={t('filters.ministries')}
          url={apiMinistriesPath()}
          renderOption={({ id, name, ...props }: Ministry & FilterOptionProps) =>
            <FilterOption key={id} icon={faUsers} label={name} {...props} />}
          onSelectItem={(item: Ministry) => dispatch({ type: 'select', itemType: 'Ministry', item })}
          selected={filters.Ministry}
        />
        <Filter
          label={t('filters.political_parties')}
          url={apiPoliticalPartiesPath()}
          renderOption={({ id, name, ...props }: PoliticalParty & FilterOptionProps) =>
            <FilterOption key={id} icon={faLandmark} label={name} {...props} />}
          onSelectItem={(item: PoliticalParty) => dispatch({ type: 'select', itemType: 'PoliticalParty', item })}
          selected={filters.PoliticalParty}
        />
      </div>
    </div>
  );
}

type PersonActiveFiltersProps = {
  filters: PersonFilters,
  dispatch: Dispatch<FilterAction<PersonFilterTypes>>
};

function PersonActiveFilters({ filters, dispatch }: PersonActiveFiltersProps) {
  return (
    <div className="d-inline-flex flex-wrap gap-2 mt-2">
      {Object.values(filters.Position).map((item: Position) => (
        <FilterPill
          key={item.id}
          icon={faUserTag}
          dispatch={dispatch}
          itemType="Position"
          item={item}
        >
          {item.name}
        </FilterPill>
      ))}
      {Object.values(filters.Ministry).map((item: Ministry) => (
        <FilterPill
          key={item.id}
          icon={faUsers}
          dispatch={dispatch}
          itemType="Ministry"
          item={item}
        >
          {item.name}
        </FilterPill>
      ))}
      {Object.values(filters.PoliticalParty).map((item: PoliticalParty) => (
        <FilterPill
          key={item.id}
          icon={faLandmark}
          dispatch={dispatch}
          itemType="PoliticalParty"
          item={item}
        >
          {item.name}
        </FilterPill>
      ))}
      <ResetFiltersButton dispatch={dispatch} />
    </div>
  );
}

function PersonList() {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [showLoadMore, setShowLoadMore] = useState(false);
  const {
    filters, dispatch, hasFilters, initializing: filterInitializing,
  } = useFilter<PersonFilterTypes, FilterAction<PersonFilterTypes>>({
    reducer: personFilterReducer,
    initialState: defaultPersonFilter(),
  });
  const params = useMemo(() => ({ ...exportFilters(filters), search }), [filters, search]);
  const [cursor, setCursor] = useCursor(params);
  const [{ items, hasNext }, { loading }] = useApiCollection<Person>(
    {
      url: `${apiPeoplePath()}?${toQueryString(cursor ? { ...params, cursor } : params)}`,
      accumulate: cursor !== null,
    },
    { manual: filterInitializing },
  );
  const nextCursor = useNextCursor('-last_name', items);
  const fetchNext = () => setCursor(nextCursor);

  return (
    <>
      <Row>
        <Col sm={{ offset: 1, span: 10 }}>
          <PageTitle>{t('menu.people_db')}</PageTitle>
        </Col>
      </Row>

      <Row>
        <Col sm={{ offset: 1, span: 10 }}>
          <SearchBar
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={{ offset: 1, span: 10 }} className="d-flex">
          <PersonListOptions
            dispatch={dispatch}
            filters={filters}
          />
        </Col>
      </Row>

      {hasFilters && (
        <Row>
          <Col sm={{ offset: 1, span: 10 }}>
            <PersonActiveFilters
              dispatch={dispatch}
              filters={filters}
            />
          </Col>
        </Row>
      )}

      <Row className="mt-4">
        <Col sm={{ offset: 1, span: 10 }}>
          <Row role="list">
            {items.map((person) => <PersonCard {...person} key={person.id} />)}
          </Row>
          <div className="text-center">
            {loading && <LoadingIndicator />}
            {!hasNext && !loading && items.length === 0 && (
              <NothingFound
                icon={faUserLargeSlash}
                title={t('message.no_people_found')}
                subtitle={t('message.no_people_found_explanation')}
              />
            )}
            {!hasNext && !loading && items.length > 0 && <p>{t('message.no_more_people')}</p>}
            {hasNext && showLoadMore && <FetchMoreButton onClick={fetchNext} />}
          </div>
          <VisibilityDetector
            onVisibilityStart={fetchNext}
            onVisibilityStop={() => setShowLoadMore(false)}
            onVisibilityInitialize={setShowLoadMore}
          />
        </Col>
      </Row>
    </>
  );
}

export default PersonList;
