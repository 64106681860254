import Fuse from 'fuse.js';
import React, {
  useEffect, useState,
} from 'react';
import {
  Accordion,
  Card,
  Col, Container,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { exportedParams, userLocale } from '@/application/atoms/exports';
import { SearchBar } from '@/application/components/SearchBar';
import AgencyCard from '@/application/pages/faq/components/AgencyCard';
import { User } from '@/shared/types/models';

import AdvisorCard from './AdvisorCard';
import TopNav from './TopNav';

type FaqItemType = {
  id: string
  question: string
  answer: string
};

export type FaqItems = {
  [key: string]: FaqItemType[]
};

type FaqProps = {
  advisors?: User[]
  faqItems: FaqItems
};

const FAQ_LANGUAGES_AVAILABLE = ['nl', 'en'];

function FaqItem({ id, question, answer }: FaqItemType) {
  return (
    <Accordion.Item eventKey={id}>
      <Accordion.Header>{question}</Accordion.Header>
      <Accordion.Body dangerouslySetInnerHTML={{ __html: answer }} />
    </Accordion.Item>
  );
}

function searchInJson(fuse, query: string) {
  if (!query) {
    return fuse.getIndex().docs;
  }

  return fuse.search(query).map((result) => result.item);
}

function validateLanguage(locale: string) {
  if (FAQ_LANGUAGES_AVAILABLE.includes(locale)) {
    return locale;
  }
  return 'en';
}

function Faq({ advisors = [], faqItems }: FaqProps) {
  const { t, i18n } = useTranslation();
  const defaultLocale = validateLanguage(useRecoilValue(userLocale));
  const {
    userId, agencyName, agencyEmail, agencyPhoneNumber,
  } = useRecoilValue(exportedParams);
  const [searchResults, setSearchResults] = useState(faqItems[defaultLocale]);
  const [currentLanguage, setCurrentLanguage] = useState(defaultLocale);
  const [query, setQuery] = useState('');

  useEffect(() => {
    const fuse = new Fuse(
      faqItems[currentLanguage],
      { keys: ['question', 'answer'] },
    );

    setSearchResults(
      searchInJson(fuse, query),
    );
    i18n.changeLanguage(currentLanguage);
  }, [query, currentLanguage]);

  return (
    <>
      <TopNav
        setCurrentLanguage={setCurrentLanguage}
        currentLanguage={currentLanguage}
        userId={userId}
      />
      <Container>
        <h1 className="pt-5">{t('title.faq')}</h1>

        <h2>{t('support_call.contact_us')}</h2>
        {advisors && (
          <Row xs={1} md={2} className="g-4" role="list">
            {advisors.map((advisor) => (
              <Col key={advisor.id}>
                <AdvisorCard {...advisor} />
              </Col>
            ))}
            <Col key="agencyCard">
              <AgencyCard name={agencyName} email={agencyEmail} phoneNumber={agencyPhoneNumber} />
            </Col>
          </Row>
        )}

        <h2 className="pt-5 mb-4">{t('menu.faq')}</h2>

        <Row>
          <Col className="pb-2">
            <SearchBar
              onChange={(e) => setQuery(e.target.value)}
            />
          </Col>
        </Row>

        <Row>
          <Col className="py-2">
            <Card>
              <Card.Body className="py-0">
                <Accordion className="faq-accordion">
                  {searchResults.map((faqItem) => (
                    <FaqItem key={faqItem.id} {...faqItem} />
                  ))}
                </Accordion>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Faq;
