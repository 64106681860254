import { faCheck, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiBookmarkFolderPath } from '@routes';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { BookmarkFolderFormSchema } from '@/application/schema';
import LineDivider from '@/shared/components/LineDivider';
import InputField from '@/shared/form/InputField';
import { client } from '@/shared/hooks/useApi';
import { BookmarkFolder } from '@/shared/types/models';

type EditBookmarkFolderModalProps = {
  folder: BookmarkFolder;
  show: boolean;
  closeHandler: () => void
  callbackHandler?: Function;
};

const editBookmarkFolderRequest = (id, data) => client.put(apiBookmarkFolderPath(id), data);

function EditBookmarkFolderModal({
  folder, show, closeHandler, callbackHandler,
}: EditBookmarkFolderModalProps) {
  const { t } = useTranslation();

  const editBookmarkFolder = (data) => {
    editBookmarkFolderRequest(folder.id, { ...data })
      .then((responseData) => {
        if (typeof (callbackHandler) === typeof (Function)) {
          callbackHandler(responseData.data);
        }
        toast.success(t('bookmarks.folder.added'));
      })
      .catch(() => {
        toast.error(t('bookmarks.folder.error'));
      });
    closeHandler();
  };

  if (!folder) {
    return null;
  }

  return (
    <Modal show={show} onHide={closeHandler} centered>
      <Formik
        initialValues={{ name: folder.name }}
        onSubmit={editBookmarkFolder}
        validationSchema={BookmarkFolderFormSchema}
      >
        {() => (
          <Form>
            <Modal.Body>
              <div className="d-flex align-items-center">
                <h2 className="mb-0">
                  {t('bookmarks.folder.edit')}
                </h2>
                <button
                  type="button"
                  className="btn-close ms-auto"
                  aria-label={t('button.close')}
                  onClick={closeHandler}
                />
              </div>

              <LineDivider />

              <InputField name="name" label={t('bookmarks.folder.name')} required />

              <div className="d-flex justify-content-end">
                <Button variant="outline-light" className="me-2" onClick={closeHandler}>
                  {t('button.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  {t('button.save')}
                </Button>
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

type EditBookmarkButtonProps = {
  folder: BookmarkFolder
  callbackHandler?: Function;
};

function EditBookmarkButton({ folder, callbackHandler }: EditBookmarkButtonProps) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <>
      <Dropdown.Item onClick={() => setShow(true)} className="text-neutral-90">
        <FontAwesomeIcon icon={faPencil} className="pe-2 small" />
        {t('button.edit')}
      </Dropdown.Item>
      <EditBookmarkFolderModal
        folder={folder}
        show={show}
        closeHandler={() => setShow(false)}
        callbackHandler={callbackHandler}
      />
    </>
  );
}

export default EditBookmarkButton;
