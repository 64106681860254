import { frequencyEmailPreferencesPath } from '@routes';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import EmailPreferencesSwitch from '@/application/components/EmailPreferencesSwitch';
import { useAxios } from '@/shared/hooks/useApi';

type Props = {
  enabled: boolean
  setShowIssues: (b: boolean) => void
};

function AgendaEnabled({ enabled: e, setShowIssues }: Props) {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(e);
  const firstUpdate = useRef(true);

  const [, submit] = useAxios({
    url: frequencyEmailPreferencesPath(),
    method: 'POST',
  }, { manual: true });

  useEffect(() => {
    // Prevents submission on first render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    submit({
      data: {
        agenda_enabled: enabled,
      },
    }).then(() => {
      toast.success(t('email_preferences.saved'));
      setShowIssues(enabled);
    }).catch(() => {
      toast.error(t('errors.unknown'));
    });
  }, [enabled]);

  return (
    <EmailPreferencesSwitch
      id="agendaFrequency"
      label={t('email_preferences.frequency.agenda')}
      hint={t('email_preferences.frequency.agenda_explanation')}
      state={enabled}
      onChange={(b: boolean) => setEnabled(b)}
    />
  );
}

export default AgendaEnabled;
