import {
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import EmailPreferencesSwitch from '@/application/components/EmailPreferencesSwitch';
import AdvancedSettingsLink from '@/application/pages/email_preferences/components/AdvancedSettingsLink';
import NoEmailWarning from '@/application/pages/email_preferences/components/NoEmailWarning';
import LineDivider from '@/shared/components/LineDivider';
import { StakeholderCategoryPreferences } from '@/shared/types/emailPreferences';

interface CategoryRowProps {
  id: string
  name: string
  enabled: boolean
  dispatch: Dispatch<any>
}

function CategoryRow({
  id, name, enabled, dispatch,
}: CategoryRowProps) {
  return (
    <div className="mb-3">
      <EmailPreferencesSwitch
        id={id}
        label={name}
        state={enabled}
        onChange={(state: boolean) => dispatch({ type: 'toggle', id, enabled: state })}
      />
    </div>
  );
}

interface StakeholderStepProps {
  toIssueStep: () => void
  toConfirmStep: () => void
  stakeholderCategories: StakeholderCategoryPreferences[]
  categoriesEnabled: any
  dispatch: Dispatch<any>
}

function StakeholderStep({
  toIssueStep, toConfirmStep, stakeholderCategories, categoriesEnabled, dispatch,
}: StakeholderStepProps) {
  const { t } = useTranslation();

  const numberOfCategories = (): number => Object.keys(categoriesEnabled).length;
  const selected = () => Object.values(categoriesEnabled).filter((value) => value === true);
  const allSelected = () => selected().length === numberOfCategories();
  const noneSelected = () => selected().length === 0;

  const selectAllHandler = () => {
    if (allSelected()) {
      dispatch({ type: 'disableAll' });
    } else {
      dispatch({ type: 'enableAll' });
    }
  };

  return (
    <>
      <div>
        <h3 className="mb-3">{t('email_preferences.stakeholder.title')}</h3>
        <p className="text-neutral-90 mb-4">{t('email_preferences.stakeholder.subtitle')}</p>
      </div>

      <Card>
        <Card.Body className="p-5">
          <div className="mb-4">
            <h3 className="mb-3">{t('email_preferences.stakeholder.card_title')}</h3>
            <div className="mb-3">
              <EmailPreferencesSwitch
                id="selectAll"
                label={t('email_preferences.stakeholder.select_all', { count: numberOfCategories() })}
                state={allSelected()}
                onChange={selectAllHandler}
              />
            </div>
          </div>

          <LineDivider />

          {stakeholderCategories.map(({ id, name }) => (
            <CategoryRow
              key={id}
              id={id}
              name={name}
              dispatch={dispatch}
              enabled={categoriesEnabled[id]}
            />
          ))}

          {noneSelected() && (
            <>
              <LineDivider />
              <NoEmailWarning />
            </>
          )}

          <LineDivider />

          <div className="d-flex justify-content-between">
            <Button variant="outline-light" onClick={toIssueStep}>
              <FontAwesomeIcon className="pe-2" icon={faAngleLeft} />
              {t('email_preferences.buttons.to_issue_step')}
            </Button>

            <Button variant="primary" onClick={toConfirmStep}>
              {t('email_preferences.buttons.to_confirm_step')}
              <FontAwesomeIcon className="ps-2" icon={faAngleRight} />
            </Button>
          </div>
        </Card.Body>
      </Card>

      <AdvancedSettingsLink />
    </>
  );
}

export default StakeholderStep;
