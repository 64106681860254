import { faTag } from '@fortawesome/pro-regular-svg-icons';
import {
  apiAgendaCategoriesPath,
} from '@routes';
import React, { Dispatch } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  Filter, FilterAction, FilterOption, FilterOptionProps,
} from '@/application/components/Filter';
import { AgendaFilters } from '@/application/pages/agenda/components/AgendaActiveFilters';
import {
  AgendaCategory, Model, Person,
} from '@/shared/types/models';

type AgendaListOptionsProps = {
  dispatch: Dispatch<FilterAction<Model>>
  filters: AgendaFilters
  week: number
  year: number
  minYear: number
  maxYear: number
  setWeek: (number) => void
  setYear: (number) => void
};

function AgendaListOptions({
  dispatch, filters, week, year, minYear, maxYear, setWeek, setYear,
} : AgendaListOptionsProps) {
  const { t } = useTranslation();

  const handleYearChange = (e) => {
    setYear(parseFloat(e.target.value));
  };

  const handleWeekChange = (e) => {
    setWeek(parseFloat(e.target.value));
  };

  const prevWeek = () => {
    if (week === 1) {
      setYear(year - 1);
      setWeek(52);
    } else {
      setWeek(week - 1);
    }
  };
  const nextWeek = () => {
    if (week === 52) {
      setYear(year + 1);
      setWeek(1);
    } else {
      setWeek((week + 1));
    }
  };

  const yearOptions = (): number[] => {
    const options = [];
    for (let i = minYear; i <= maxYear; i += 1) {
      options.push(i);
    }
    return options;
  };

  const weekOptions = (): number[] => {
    const options = [];
    for (let i = 1; i <= 52; i += 1) {
      options.push(i);
    }
    return options;
  };

  return (
    <Row className="mt-4">
      <Col md={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }}>
        <Filter
          label={t('filters.agenda_categories')}
          url={apiAgendaCategoriesPath()}
          renderOption={({ id, name, ...props }: Person & FilterOptionProps) =>
            <FilterOption key={id} icon={faTag} label={name} {...props} />}
          onSelectItem={(item: AgendaCategory) => dispatch({ type: 'select', itemType: 'AgendaCategory', item })}
          selected={filters.AgendaCategory}
        />
      </Col>
      <Col md={{ span: 4, order: 2 }} xs={{ span: 12, order: 1 }} className="mb-3 mb-lg-0">
        <div className="d-flex justify-lg-content-center">
          <button type="button" className="btn btn-link navigate-period-btn py-0 ps-1" onClick={prevWeek}>
            <i className="fa-solid fa-chevron-left" />
          </button>
          <select className="form-select w-auto me-1 py-0" onChange={handleYearChange} value={year} id="selectedYear">
            {yearOptions().map((y) => <option key={y} value={y}>{y}</option>)}
          </select>
          <select className="form-select w-auto ms-1 py-0" onChange={handleWeekChange} value={week} id="selectedWeek">
            {weekOptions().map((w) => (
              <option key={w} value={w}>
                {`${t('views.agendas.filters.week')} ${w}`}
              </option>
            ))}
          </select>
          <button type="button" className="btn btn-link navigate-period-btn py-0 pe-1" onClick={nextWeek}>
            <i className="fa-solid fa-chevron-right" />
          </button>
        </div>
      </Col>
    </Row>
  );
}

export default AgendaListOptions;
