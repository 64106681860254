import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiAgendaFeedPath } from '@routes';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useModalForm } from '@/shared/components/ModalForm';
import { AgendaFeedFormModal } from '@/application/pages/agenda_feeds/components/AgendaFeedFormModal';
import { useAxios } from '@/shared/hooks/useApi';
import { Model } from '@/shared/types/models';

function AgendaFeedEditButton({ id }: Pick<Model, 'id'>) {
  const { t } = useTranslation();
  const [{ loading }, refetch] = useAxios(
    { url: apiAgendaFeedPath(id) },
    { manual: true },
  );
  const {
    dismiss, state: modalState, dispatch: dispatchModal, edit,
  } = useModalForm({});

  return (
    <>
      <Button
        variant="outline-light"
        onClick={() => refetch()
          .then(({ data }) => {
            edit({ ...data, agenda_category_ids: data.categories.map((c) => c.id) });
          })}
        disabled={loading}
        title={t('button.edit')}
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>

      <AgendaFeedFormModal
        dispatchModal={dispatchModal}
        onSave={() => window.location.reload()}
        onHide={dismiss}
        mode="edit"
        showQuickAddition={false}
        {...modalState}
      />
    </>
  );
}

export default AgendaFeedEditButton;
