import { apiArticlesPath, articlePath } from '@routes';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FetchMoreButton } from '@/application/components/FetchMoreButton';
import VisibilityDetector from '@/application/components/VisibilityDetector';
import LoadingIndicator from '@/shared/components/LoadingIndicator';
import { useApiCollection } from '@/shared/hooks/useApi';
import { useNextCursor } from '@/shared/hooks/useCursor';
import { Article } from '@/shared/types/models';

function ArticleFeedItem({ id, title, summary_truncated }: Article) {
  return (
    <div className="article-row p-3" role="article">
      <h3 className="mb-3 text-primary-shade-1">
        {title}
      </h3>

      <a href={articlePath(id)} className="stretched-link">
        <span className="visually-hidden">Read more</span>
      </a>

      <div className="d-inline-flex flex-wrap gap-2">
        <small dangerouslySetInnerHTML={{ __html: summary_truncated }} />
      </div>
    </div>
  );
}

function ArticleFeed() {
  const { t } = useTranslation();
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [params, setParams] = useState({});
  const [{ items, hasNext }, { loading }] = useApiCollection<Article>({
    url: `${apiArticlesPath()}`,
    accumulate: true,
    params,
  });
  const nextCursor = useNextCursor('-published_at', items);
  const fetchNext = () => setParams((p) => ({ ...p, cursor: nextCursor }));

  return (
    <>
      <Card>
        <Card.Body className="px-0">
          <div className="px-3">
            <h3 className="mb-0">{t('title.recent_articles')}</h3>
          </div>

          {items.map((article) => <ArticleFeedItem key={article.id} {...article} />)}
        </Card.Body>
        <Card.Body className="pt-0">
          {loading && <LoadingIndicator />}
          {!hasNext && !loading && <div>{t('message.no_more_articles')}</div>}
          {hasNext && showLoadMore && <FetchMoreButton onClick={fetchNext} />}
        </Card.Body>
      </Card>
      {items.length > 0 && (
      <VisibilityDetector
        onVisibilityStart={fetchNext}
        onVisibilityStop={() => setShowLoadMore(false)}
        onVisibilityInitialize={setShowLoadMore}
      />
      )}
    </>
  );
}

export default ArticleFeed;
