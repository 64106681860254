import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CreateBookmarkFolderModal from '@/application/pages/bookmarks/components/CreateBookmarkFolderModal';

type Props = {
  callbackHandler: () => void
};

function NewBookmarkButton({ callbackHandler }: Props) {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <>
      <Button
        className="mb-3"
        size="sm"
        variant="primary"
        onClick={() => setShowCreateModal(true)}
      >
        <FontAwesomeIcon icon={faPlus} className="pe-2" />
        {t('bookmarks.folder.add')}
      </Button>
      <CreateBookmarkFolderModal
        newFolderHandler={callbackHandler}
        showModal={showCreateModal}
        closeHandler={() => setShowCreateModal(false)}
      />
    </>
  );
}

export default NewBookmarkButton;
