import {
  faAngleLeft,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { changeEmailPreferencesPath, emailPreferencesPath } from '@routes';
import React, { useReducer } from 'react';
import {
  Card,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import SaveButton from '@/application/components/SaveButton';
import CompanyIssues from '@/application/pages/email_preferences/advanced/CompanyIssues';
import {
  issueReducerAdvanced,
  stakeholderCategoryReducerAdvanced,
} from '@/application/pages/email_preferences/advanced/reducers';
import {
  transformCompanyIssuesAdvanced,
  transformIssuesForSubmissionAdvanced,
  transformStakeholderCategoriesAdvanced,
  transformStakeholderCategoriesForSubmissionAdvanced,
} from '@/application/pages/email_preferences/advanced/transformers';
import LineDivider from '@/shared/components/LineDivider';
import { useAxios } from '@/shared/hooks/useApi';
import { CompanyWithIssuePreferences, StakeholderCategoryPreferences } from '@/shared/types/emailPreferences';

import StakeholderCategoryHeader from './StakeholderCategoryHeader';
import StakeholderCategoryRow from './StakeholderCategoryRow';

interface Props {
  stakeholderCategories: StakeholderCategoryPreferences[]
  companyIssues: CompanyWithIssuePreferences[]
}

function AdvancedSettings({ companyIssues, stakeholderCategories }: Props) {
  const { t } = useTranslation();

  const [categoryPreferences, categoryDispatch] = useReducer(
    stakeholderCategoryReducerAdvanced,
    stakeholderCategories.reduce(transformStakeholderCategoriesAdvanced, {}),
  );
  const [issuePreferences, issueDispatch] = useReducer(
    issueReducerAdvanced,
    companyIssues.reduce(transformCompanyIssuesAdvanced, {}),
  );

  // eslint-disable-next-line no-empty-pattern
  const [{}, submit] = useAxios({
    url: changeEmailPreferencesPath(),
    method: 'POST',
  }, { manual: true });

  const submitPreferences = () => {
    const issues = transformIssuesForSubmissionAdvanced(issuePreferences);
    const categories = transformStakeholderCategoriesForSubmissionAdvanced(categoryPreferences);
    submit({
      data: {
        user: {
          included_category_ids: categories,
          included_list_ids: issues,
        },
      },
    }).then(() => {
      toast.success(t('email_preferences.saved'));
      setTimeout(() => {
        window.location.href = emailPreferencesPath();
      }, 3000);
    }).catch(() => {
      toast.error(t('errors.unknown'));
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div>
          <h3 className="mb-3">{t('email_preferences.advanced.title')}</h3>
          <p className="text-neutral-90 mb-3">{t('email_preferences.advanced.subtitle')}</p>
          <a href={emailPreferencesPath()}>
            <FontAwesomeIcon className="pe-2" icon={faAngleLeft} />
            {t('email_preferences.buttons.to_simple_settings')}
          </a>
        </div>
        <div>
          <SaveButton onClickHandler={submitPreferences} />
        </div>
      </div>

      <Card className="mb-5">
        <Card.Body className="p-5">
          <h2 className="mb-0">{t('email_preferences.advanced.issues')}</h2>
          {companyIssues.map((company) => (
            <CompanyIssues
              key={company.name}
              preferenceState={issuePreferences}
              company={company}
              dispatch={issueDispatch}
            />
          ))}

          <LineDivider margin="3" />
          <SaveButton onClickHandler={submitPreferences} />
        </Card.Body>
      </Card>

      <Card>
        <Card.Body className="p-5">
          <h2 className="mb-0">{t('email_preferences.advanced.stakeholders')}</h2>
          <StakeholderCategoryHeader />
          {stakeholderCategories.map((category) => (
            <StakeholderCategoryRow
              key={category.id}
              id={category.id}
              name={category.name}
              dispatch={categoryDispatch}
              preferences={categoryPreferences[category.id]}
            />
          ))}

          <LineDivider margin="3" />
          <SaveButton onClickHandler={submitPreferences} />
        </Card.Body>
      </Card>
    </>
  );
}

export default AdvancedSettings;
