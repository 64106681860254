import {
  faCalendarPlus, faChevronLeft, faChevronRight, faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiAgendaFeedPath, apiAgendaFeedsPath } from '@routes';
import { Form as InnerForm, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Button, CloseButton, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Step, Steps } from '@/application/components/Steps';
import { AgendaCategorySelectField } from '@/application/pages/agenda_feeds/components/AgendaCategorySelectField';
import { AgendaFeedFormSchema } from '@/application/schema';
import LineDivider from '@/shared/components/LineDivider';
import { ModalFormProps } from '@/shared/components/ModalForm';
import InputField from '@/shared/form/InputField';
import { client } from '@/shared/hooks/useApi';
import { icalAgendaFeedPath } from '@/shared/utilities/agendaFeeds';
import { pipeApiErrors } from '@/shared/utilities/api';

type AgendaFeedModalProps = ModalFormProps & {
  // whether or not to show the final step
  // that allows for quick addition to the agenda
  // after save
  showQuickAddition?: boolean
};

export function AgendaFeedFormModal({
  onSave,
  onHide,
  mode,
  values,
  showQuickAddition = true,
  ...props
}: AgendaFeedModalProps) {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [latestId, setLatestId] = useState(-1);
  const handleSubmit = (_values, actions) => {
    const save = mode === 'create'
      ? (data) => client.post(apiAgendaFeedsPath(), data)
      : (data) => client.put(apiAgendaFeedPath(_values.id), data);

    save({ agenda_feed: _values })
      .then((r) => {
        setLatestId(r.data.id);

        actions.setSubmitting(false);
        if (showQuickAddition) {
          setCurrentStep((idx) => idx + 1);
        }
        if (onSave) {
          onSave();
        }
      })
      .catch(pipeApiErrors((errors) => {
        actions.setSubmitting(false);
        actions.setErrors(errors);
        setCurrentStep(0);
      }));
  };
  const stepsRef = useRef<HTMLDivElement>();

  useEffect(() => {
    // highlights first input in the active step
    const $el = stepsRef.current;
    if ($el) {
      const stepInput = $el.querySelector<HTMLInputElement>(`.step-${currentStep} input`);
      if (stepInput) {
        stepInput.focus();
      }
    }
  }, [currentStep]);

  return (
    <Modal
      size="lg"
      centered
      contentClassName="rounded-2"
      onHide={onHide}
      {...props}
    >
      <Formik
        initialValues={values}
        onSubmit={handleSubmit}
        validationSchema={AgendaFeedFormSchema}
      >
        {({ errors, isValidating, handleReset }) => (
          <InnerForm>
            <div className="p-5">
              <div className="d-flex bb-neutral-10 pb-3">
                <div className="flex-fill text-dark">
                  <h2 className="mb-0">
                    {mode === 'create' ? t('agenda_feeds.title.new') : t('agenda_feeds.title.edit')}
                  </h2>
                  <small className="text-neutral-70">{t('agenda_feeds.title.new_sub')}</small>
                </div>
                <div>
                  <CloseButton onClick={onHide} />
                </div>
              </div>
              <Steps
                ref={stepsRef}
                current={currentStep}
                className="pt-3"
              >
                <Step>
                  <InputField name="title" label={t('agenda_feeds.form_label.title')} groupClass="mb-4" />
                  <LineDivider />
                  <div className="d-flex flex-fill justify-content-end gap-2">
                    <Button
                      className="small"
                      size="sm"
                      variant="outline-light"
                      onClick={(e) => {
                        e.preventDefault();
                        onHide();
                      }}
                    >
                      {t('button.cancel')}
                    </Button>
                    <Button
                      className="small"
                      size="sm"
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentStep((current) => current + 1);
                      }}
                      disabled={!!errors.title || isValidating}
                    >
                      {t('agenda_feeds.button.select_categories')}
                      <FontAwesomeIcon icon={faChevronRight} size="sm" className="ms-2" />
                    </Button>
                  </div>
                </Step>
                <Step>
                  <AgendaCategorySelectField
                    name="agenda_category_ids"
                    label={t('agenda_feeds.form_label.agenda_categories')}
                    className="mb-4"
                  />
                  <LineDivider />
                  <div className="d-flex flex-fill justify-content-end gap-2">
                    <Button
                      variant="outline-light"
                      onClick={() => setCurrentStep((number) => number - 1)}
                      size="sm"
                      className="small"
                    >
                      <FontAwesomeIcon icon={faChevronLeft} size="sm" className="me-2" />
                      {t('button.back')}
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      className="small"
                    >
                      {t('button.save')}
                      <FontAwesomeIcon icon={faChevronRight} size="sm" className="ms-2" />
                    </Button>
                  </div>
                </Step>
                <Step>
                  <div className="mb-4">
                    <h3 className="mb-2">{t('confirmations.title')}</h3>
                    <p>{t('agenda_feeds.add_directly')}</p>
                    <p>
                      <Button
                        size="sm"
                        variant="outline-light"
                        href={icalAgendaFeedPath(latestId)}
                        className="px-3 lh-32 py-0"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faCalendarPlus} className="pe-2" />
                        {t('agenda_feeds.add_to_calendar_button')}
                      </Button>
                    </p>
                  </div>
                  <LineDivider />
                  <div className="d-flex flex-fill justify-content-end gap-2">
                    {mode === 'create' && (
                      <Button
                        variant="outline-light"
                        onClick={() => {
                          setLatestId(-1);
                          setCurrentStep(0);
                          handleReset();
                        }}
                        size="sm"
                        className="small"
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          size="sm"
                          className="me-2"
                        />
                        {t('agenda_feeds.create_another_feed')}
                      </Button>
                    )}
                    <Button
                      size="sm"
                      onClick={onHide}
                      className="small"
                    >
                      {t('button.finish')}
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        size="sm"
                        className="ms-2"
                      />
                    </Button>
                  </div>
                </Step>
              </Steps>
            </div>
          </InnerForm>
        )}
      </Formik>
    </Modal>
  );
}
