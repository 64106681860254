import { useField } from 'formik';
import React from 'react';
import Form from 'react-bootstrap/Form';

import { ErrorMessage } from '@/shared/form/ErrorMessage';
import { TextAreaFieldProps } from '@/shared/types/form';

export default function TextAreaField({
  label, helpText, placeholder, name, required, rows = 3, ...props
}: TextAreaFieldProps): JSX.Element {
  const [field, meta] = useField(name);
  return (
    <Form.Group className="mb-3" controlId={field.name}>
      <Form.Label>
        {label}
      </Form.Label>
      <Form.Control
        name={name}
        rows={rows}
        as="textarea"
        placeholder={placeholder}
        {...field}
        required={required}
        {...props}
      />
      {helpText
          && <Form.Text className="text-muted">{helpText}</Form.Text>}

      {meta.touched && meta.error ? (
        <ErrorMessage error={meta.error} />
      ) : null}
    </Form.Group>
  );
}
