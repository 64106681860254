import React from 'react';

import PrintButton from '@/application/components/PrintButton';
import Layout from '@/application/layouts/Layout';

function PeopleActionButtons() {
  return (
    <Layout>
      <PrintButton />
    </Layout>
  );
}

export default PeopleActionButtons;
