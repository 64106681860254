import {
  frequencyEmailPreferencesPath,
} from '@routes';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import EmailPreferencesSwitch from '@/application/components/EmailPreferencesSwitch';
import NoEmailWarning from '@/application/pages/email_preferences/components/NoEmailWarning';
import LineDivider from '@/shared/components/LineDivider';
import { useAxios } from '@/shared/hooks/useApi';
import { EmailPreferenceFrequency } from '@/shared/types/emailPreferences';

interface Props {
  enableToasts?: boolean,
  receive_empty_weekly: boolean,
}

function ToggleFrequencies({
  direct, daily, weekly, enableToasts = true, receive_empty_weekly,
}: EmailPreferenceFrequency & Props) {
  const { t } = useTranslation();
  const [directEnabled, setDirectEnabled] = useState(direct.enabled);
  const [dailyEnabled, setDailyEnabled] = useState(daily.enabled);
  const [weeklyEnabled, setWeeklyEnabled] = useState(weekly.enabled);
  const [receiveEmptyWeekly, setReceiveEmptyWeeklyEnabled] = useState(receive_empty_weekly);
  const firstUpdate = useRef(true);

  // eslint-disable-next-line no-empty-pattern
  const [{}, submit] = useAxios({
    url: frequencyEmailPreferencesPath(),
    method: 'POST',
  }, { manual: true });

  useEffect(() => {
    // Prevents submission on first render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    submit({
      data: {
        direct_enabled: directEnabled,
        daily_enabled: dailyEnabled,
        weekly_enabled: weeklyEnabled,
        receive_empty_weekly: receiveEmptyWeekly,
      },
    }).then(() => {
      if (enableToasts) {
        toast.success(t('email_preferences.saved'));
      }
    }).catch(() => {
      if (enableToasts) {
        toast.error(t('errors.unknown'));
      }
    });
  }, [directEnabled, dailyEnabled, weeklyEnabled, receiveEmptyWeekly]);

  return (
    <>
      <h3>{t('email_preferences.frequency.card_title')}</h3>

      <div className="mb-3">
        <EmailPreferencesSwitch
          id="directFrequency"
          label={t('email_preferences.frequency.direct')}
          hint={t('email_preferences.frequency.direct_explanation')}
          state={directEnabled}
          onChange={(b: boolean) => setDirectEnabled(b)}
        />
      </div>
      <div className="mb-3">
        <EmailPreferencesSwitch
          id="dailyFrequency"
          label={t('email_preferences.frequency.daily')}
          hint={t('email_preferences.frequency.daily_explanation')}
          state={dailyEnabled}
          onChange={(b: boolean) => setDailyEnabled(b)}
          suggested
        />
      </div>
      <div className="mb-3">
        <EmailPreferencesSwitch
          id="weeklyFrequency"
          label={t('email_preferences.frequency.weekly')}
          hint={t('email_preferences.frequency.weekly_explanation')}
          state={weeklyEnabled}
          onChange={(b: boolean) => setWeeklyEnabled(b)}
        />
      </div>

      <h3 className="mt-5">{t('email_preferences.other.title')}</h3>
      <div className="mb-3">
        <EmailPreferencesSwitch
          id="receiveEmptyWeekly"
          label={t('email_preferences.other.receive_empty_weeklies')}
          state={receiveEmptyWeekly}
          onChange={(b: boolean) => setReceiveEmptyWeeklyEnabled(b)}
        />
      </div>

      {!directEnabled && !dailyEnabled && !weeklyEnabled && (
        <>
          <LineDivider />
          <NoEmailWarning />
        </>
      )}

    </>
  );
}

export default ToggleFrequencies;
