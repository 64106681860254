export const transformCompanyIssuesFlow = (accumulator, company) => {
  company.issues.forEach((issue) => {
    accumulator[issue.id] = true;
  });
  return accumulator;
};

export const transformStakeholderCategoriesFlow = (accumulator, category) => {
  accumulator[category.id] = true;
  return accumulator;
};

export const transformForSubmissionFlow = (resourceList) => {
  const direct = [];
  const daily = [];
  const weekly = [];
  Object.entries(resourceList).forEach(([id, enabled]) => {
    if (enabled) {
      direct.push(id);
      daily.push(id);
      weekly.push(id);
    }
  });
  return {
    direct, daily, weekly,
  };
};
