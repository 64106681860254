import React, { ChangeEventHandler } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>
  className?: string
};

export function CompactSwitch({ onChange, className }: Props) {
  const { t } = useTranslation();
  return (
    <Form className={className}>
      <Form.Check
        type="switch"
        id="compact-switch"
        label={(
          <span className="mt-1 ms-2 d-block">
            <small>{t('title.compact')}</small>
          </span>
        )}
        onChange={onChange}
        bsSwitchPrefix="form-switch form-switch-md"
      />
    </Form>
  );
}
