import React, { cloneElement, forwardRef, HTMLAttributes } from 'react';

type StepsProps = { current: string | number } & HTMLAttributes<HTMLDivElement>;

export const Steps = forwardRef<HTMLDivElement, StepsProps>(({ children, current, ...props }, ref) => {
  const isVisible = (element, idx) => {
    if (typeof element.props.name !== 'undefined') {
      return current === element.props.name;
    }
    return idx === current;
  };
  return (
    <div ref={ref} {...props}>
      {React.Children
        .toArray(children)
        .map((child: JSX.Element, i) =>
          cloneElement(
            child,
            { className: `${child.props.className || ''} ${!isVisible(child, i) ? 'd-none' : ''} step-${i}` },
          ))}
    </div>
  );
});

type StepProps = { name?: string | number } & HTMLAttributes<HTMLDivElement>;

export function Step({ children, ...props }: StepProps) {
  return <div {...props}>{children}</div>;
}

Object.assign(Steps, { Step });

export default Steps;
