import {
  faPaperPlaneTop,
  faTriangleExclamation, faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { contactIndexPath } from '@routes';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Layout from '@/application/layouts/Layout';
import { ErrorFoundFormSchema } from '@/application/schema';
import LineDivider from '@/shared/components/LineDivider';
import TextAreaField from '@/shared/form/TextAreaField';
import { useAxios } from '@/shared/hooks/useApi';

function ReportErrorButton() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  // eslint-disable-next-line no-empty-pattern
  const [{}, postForm] = useAxios({
    url: contactIndexPath(),
    method: 'POST',
  }, { manual: true });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmit = (data) => {
    const payload = {
      message: `${data.message} \n\nPage: ${window.location.href}`,
    };
    postForm({ data: payload }).then(() => {
      toast.success(t('flash.thanks_for_your_message'));
    }).catch(() => {
      toast.error(t('errors.unknown'));
    });

    handleClose();
  };

  return (
    <Layout>
      <Button variant="outline-danger" onClick={handleShow} className="report-error-button">
        <FontAwesomeIcon className="pe-2" icon={faTriangleExclamation} />
        {t('title.mistake_spotted')}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div>
              <h2 className="mb-0">{t('errors.modal.title')}</h2>
              <small className="text-neutral-70 fw-medium">{t('errors.modal.subtitle')}</small>
            </div>

            <FontAwesomeIcon
              icon={faXmark}
              className="text-neutral-70 cursor-pointer"
              size="2x"
              onClick={handleClose}
            />
          </div>

          <LineDivider margin="3" />

          <Formik
            initialValues={{ message: '' }}
            onSubmit={handleSubmit}
            validationSchema={ErrorFoundFormSchema}
          >
            {() => (
              <Form>
                <TextAreaField
                  name="message"
                  rows={6}
                  label={t('errors.modal.label')}
                  required
                />

                <div className="d-flex justify-content-end">
                  <Button variant="outline-light" className="me-2" onClick={handleClose}>
                    {t('button.cancel')}
                  </Button>
                  <Button variant="primary" type="submit">
                    <FontAwesomeIcon
                      icon={faPaperPlaneTop}
                      className="pe-2"
                    />
                    {t('errors.modal.submit')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default ReportErrorButton;
