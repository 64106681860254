import { faTag } from '@fortawesome/pro-regular-svg-icons';
import React, { Dispatch } from 'react';

import {
  FilterAction, FilterPill, Filters, ResetFiltersButton,
} from '@/application/components/Filter';
import {
  AgendaCategory, Model,
} from '@/shared/types/models';

export type AgendaFilters = Filters<AgendaCategory>;

type AgendaActiveFilterProps = {
  filters: AgendaFilters,
  dispatch: Dispatch<FilterAction<Model>>
};

function AgendaActiveFilters({ filters, dispatch }: AgendaActiveFilterProps) {
  return (
    <div className="d-inline-flex flex-wrap gap-2 mt-2">
      {Object.values(filters.AgendaCategory).map((item: AgendaCategory) => (
        <FilterPill
          key={item.id}
          icon={faTag}
          dispatch={dispatch}
          itemType="AgendaCategory"
          item={item}
        >
          {item.name}
        </FilterPill>
      ))}
      <ResetFiltersButton dispatch={dispatch} />
    </div>
  );
}

export default AgendaActiveFilters;
