import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiBookmarkFoldersPath } from '@routes';
import { Form, Formik } from 'formik';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { BookmarkFolderFormSchema } from '@/application/schema';
import LineDivider from '@/shared/components/LineDivider';
import InputField from '@/shared/form/InputField';
import { client } from '@/shared/hooks/useApi';

type CreateBookmarkFolderModalProps = {
  showModal: boolean
  closeHandler: () => void
  newFolderHandler?: Function
};

const createBookmarkFolderRequest = (data) => client.post(apiBookmarkFoldersPath(), data);

export default function CreateBookmarkFolderModal({
  showModal, closeHandler, newFolderHandler,
}: CreateBookmarkFolderModalProps) {
  const { t } = useTranslation();

  const createBookmarkFolder = (data) => {
    createBookmarkFolderRequest({ ...data })
      .then((responseData) => {
        if (typeof (newFolderHandler) === typeof (Function)) {
          newFolderHandler(responseData.data);
        }
        toast.success(t('bookmarks.folder.added'));
      })
      .catch(() => {
        toast.error(t('bookmarks.folder.error'));
      });
    closeHandler();
  };

  return (
    <Modal show={showModal} onHide={closeHandler} centered>
      <Formik
        initialValues={{ name: '' }}
        onSubmit={createBookmarkFolder}
        validationSchema={BookmarkFolderFormSchema}
      >
        {() => (
          <Form>
            <Modal.Body>
              <div className="d-flex align-items-center">
                <h2 className="mb-0">
                  {t('bookmarks.folder.add')}
                </h2>
                <button
                  type="button"
                  className="btn-close ms-auto"
                  aria-label={t('button.close')}
                  onClick={closeHandler}
                />
              </div>

              <LineDivider />

              <InputField name="name" label={t('bookmarks.folder.name')} required />

              <div className="d-flex justify-content-end">
                <Button variant="outline-light" className="me-2" onClick={closeHandler}>
                  {t('button.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  {t('button.save')}
                </Button>
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
