import { apiAgendaCategoriesPath } from '@routes';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { exportFilters, filterReducerWithDefault, useFilter } from '@/application/components/Filter';
import { PageTitle } from '@/application/components/PageTitle';
import { SearchBar } from '@/application/components/SearchBar';
import Layout from '@/application/layouts/Layout';
import AgendaActiveFilters, { AgendaFilters } from '@/application/pages/agenda/components/AgendaActiveFilters';
import AgendaListOptions from '@/application/pages/agenda/components/AgendaListOptions';
import { client } from '@/shared/hooks/useApi';

import AgendaList from './components/AgendaList';

function defaultAgendaFilters(): AgendaFilters {
  return {
    AgendaCategory: {},
  };
}

const agendaFilterReducer = filterReducerWithDefault(defaultAgendaFilters);

async function agendaFilterInitializer(dispatch) {
  const promises = [];
  const urlSearchParams = new URLSearchParams(window.location.search);

  if (urlSearchParams.has('agenda_category_id')) {
    promises.push(
      client.get(apiAgendaCategoriesPath({ pk: urlSearchParams.getAll('agenda_category_id') }))
        .then((r) => dispatch({ type: 'import', itemType: 'AgendaCategory', items: r.data.data })),
    );
  }

  await Promise.all(promises);
}

type Props = {
  currentWeek: number
  currentYear: number
  minYear: number
  maxYear: number
};

function AgendaIndex({
  currentWeek, currentYear, minYear, maxYear,
}: Props) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [week, setWeek] = useState(currentWeek);
  const [year, setYear] = useState(currentYear);

  const {
    filters, hasFilters, dispatch, initializing: filterInitializing,
  } = useFilter({
    reducer: agendaFilterReducer,
    initialState: defaultAgendaFilters(),
    initializer: agendaFilterInitializer,
    cacheKey: 'agenda-filter',
  });

  return (
    <Layout>
      <PageTitle>{t('menu.agenda')}</PageTitle>

      <Row>
        <Col>
          <SearchBar
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>

      <AgendaListOptions
        dispatch={dispatch}
        filters={filters}
        week={week}
        year={year}
        minYear={minYear}
        maxYear={maxYear}
        setWeek={setWeek}
        setYear={setYear}
      />

      {hasFilters && (
      <Row className="mt-4">
        <Col>
          <AgendaActiveFilters
            dispatch={dispatch}
            filters={filters}
          />
        </Col>
      </Row>
      )}

      <AgendaList
        params={{
          search, week, year, ...exportFilters(filters),
        }}
        suspended={filterInitializing}
      />
    </Layout>
  );
}

export default AgendaIndex;
