import { personPath } from '@routes';
import * as React from 'react';
import { AriaRole } from 'react';
import { Card, Image } from 'react-bootstrap';

import { Person } from '@/shared/types/models';

function PersonCard({
  id, full_name, avatar, position, political_party, ministry, role = 'listitem',
}: Person & { role?: AriaRole }) {
  return (
    <div className="col-xl-4 col-lg-6 col-sm-12 mb-4" role={role}>
      <Card className="person-card">
        <Card.Body>
          <div className="d-flex mb-3">
            <Image
              alt={full_name}
              title={full_name}
              className="avatar me-3"
              src={avatar.circle_url}
            />
            <div>
              <h3 className="fw-medium text-primary lh-sm mb-0">{full_name}</h3>
              {position && <div className="small lh-sm text-neutral-70">{position.name}</div>}
            </div>
          </div>

          {political_party && <small className="d-block">{political_party.name}</small>}
          {ministry && <small className="d-block">{ministry.name}</small>}

          <a className="stretched-link" aria-label={full_name} href={personPath(id)}> </a>
        </Card.Body>
      </Card>
    </div>
  );
}

export default PersonCard;
