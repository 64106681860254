import { articlePath, articlesPath } from '@routes';
import React from 'react';
import {
  Button, Card, ListGroup, Tab,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Article, BookmarkFolder } from '@/shared/types/models';

function BookmarkArticle({ id, title, summary_truncated }: Article) {
  return (
    <ListGroup.Item className="px-5 pt-5 pb-3">
      <h3 className="article-title">
        <a href={articlePath(id)} className="text-primary-shade-1">
          {title}
        </a>
      </h3>

      {/* eslint-disable-next-line react/no-danger */}
      <div className="small" dangerouslySetInnerHTML={{ __html: summary_truncated }} />
    </ListGroup.Item>
  );
}

function BookmarkFolderArticles({ id, bookmarks }: BookmarkFolder) {
  const { t } = useTranslation();
  return (
    <Tab.Pane eventKey={id}>
      {bookmarks.length === 0 && (
        <Card>
          <Card.Body>
            <h3 className="mb-3">{ t('bookmarks.folder.no_articles_found') }</h3>
            <Button href={articlesPath()}>{t('bookmarks.folder.no_articles_found_start_searching')}</Button>
          </Card.Body>
        </Card>
      )}

      {bookmarks.length > 0 && (
        <Card>
          <ListGroup variant="flush">
            {bookmarks.map(
              (bookmark) => (
                <BookmarkArticle {...bookmark.article} key={bookmark.id} />
              ),
            )}
          </ListGroup>
        </Card>
      )}
    </Tab.Pane>
  );
}

export default BookmarkFolderArticles;
