import { faAt, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, Image } from 'react-bootstrap';

import { User } from '@/shared/types/models';

function AdvisorCard({
  full_name, email, avatar, phone_number,
}: User) {
  return (
    <Card className="advisor-card">
      <Card.Body className="d-flex">
        <div className="advisor-avatar d-flex align-items-center justify-content-center overflow-hidden me-3 rounded-4">
          <Image
            fluid
            src={avatar.url}
            alt={full_name}
          />
        </div>
        <div className="d-flex flex-column">
          <div><strong>{full_name}</strong></div>
          <div className="pt-1 text-break">
            <FontAwesomeIcon className="pe-2 text-neutral-70" icon={faAt} />
            <a href={`mailto:${email}`} className="ps-1 text-break">{email}</a>
          </div>

          {phone_number
                && (
                <div className="pt-1">
                  <FontAwesomeIcon className="pe-2 text-neutral-70" icon={faPhone} />
                  <a href={`tel:${phone_number}`} className="ps-1 text-break">{phone_number}</a>
                </div>
                )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default AdvisorCard;
