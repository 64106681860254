import { useEffect, useMemo, useState } from 'react';

import { safeBase64Encode } from '@/application/encoding_helpers';
import { usePrevious } from '@/application/hooks/usePrevious';
import useChangedEffect from '@/shared/hooks/useChangedEffect';
import { shallowCompare } from '@/shared/utilities/comparison';

type OrderByParams = { column: string, direction?: string };

function orderByParams(input: string): OrderByParams {
  const isDescending = input.charAt(0) === '-';
  return {
    column: isDescending ? input.slice(1) : input,
    direction: isDescending ? 'desc' : 'asc',
  };
}

// cursor input is any object with string type keys
type CursorInput = { [k: string]: unknown };

function nextCursor<T extends CursorInput>(items: T[], order: OrderByParams): string | null {
  let cursor: string;

  if (items?.length > 0) {
    cursor = safeBase64Encode(JSON.stringify({
      order: [order.column, order.direction],
      edge: items[items.length - 1][order.column],
    }));
  }

  return cursor;
}

/**
 * Provides cursor storage and resets cursor whenever the params
 * supplied change (detection through shallow comparison)
 */
export function useCursor(params) {
  const [cursor, setCursor] = useState(null);
  useChangedEffect(params, () => setCursor(null));
  return [cursor, setCursor];
}

/**
 * Generates the next cursor by collecting the edge (`item[-1][order.column]`)
 * and the sorting order of the items so that the backend can send us the next
 * item in the collection.
 */
export function useNextCursor<T extends CursorInput>(orderParam: string, items: T[]) {
  const orderParams = orderParam ? orderByParams(orderParam) : null;

  // memoize actual cursor, so we don't recalculate on every render
  return useMemo(() => (orderParams && nextCursor(items, orderParams)), [items, orderParams]);
}

export default useNextCursor;
