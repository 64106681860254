import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import LineDivider from '@/shared/components/LineDivider';

import Layout from '../layouts/Layout';

interface Props {
  codes: string[]
}

function OtpCodeDownloader({ codes }: Props) {
  const { t } = useTranslation();

  const downloadCodes = () => {
    const element = document.createElement('a');
    const file = new Blob(['SIM 2fa backup codes\n\n', codes.join('\n')], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'sim-2fa-backup-codes.txt';
    document.body.appendChild(element);
    element.click();
  };

  return (
    <Layout>
      <LineDivider />
      <h4>{t('otp.backup_codes')}</h4>
      <p>{t('otp.backup_code_instruction')}</p>
      <ol>
        {codes.map((code) => <li key={code}>{code}</li>)}
      </ol>
      <Button onClick={downloadCodes}>{t('otp.download_backup_codes')}</Button>
    </Layout>
  );
}

export default OtpCodeDownloader;
