import React from 'react';

import Layout from '@/application/layouts/Layout';
import PersonList from '@/application/pages/people/components/PersonList';

function PeopleIndex(props) {
  return (
    <Layout>
      <PersonList {...props} />
    </Layout>
  );
}

export default PeopleIndex;
