import {
  faCalendarCirclePlus,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toCalendarApiAgendaItemPath } from '@routes';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import LoadingIndicator from '@/shared/components/LoadingIndicator';
import { useAxios } from '@/shared/hooks/useApi';

interface Props {
  id: number
  minimized?: boolean
}

function AddAgendaItemButton({ id, minimized = false }: Props) {
  const [addedToCalendar, setAddedToCalendar] = useState(false);
  const { t } = useTranslation();

  const [{ loading }, submit] = useAxios({
    url: toCalendarApiAgendaItemPath(id),
  }, { manual: true });

  const handleSubmit = (e) => {
    e.stopPropagation();
    submit().then(() => {
      setAddedToCalendar(true);
      toast.success(t('views.agendas.added_to_calendar'));
    }).catch(() => {
      toast.error(t('errors.unknown'));
    });
  };

  const icon = (
    <>
      <FontAwesomeIcon icon={faCalendarCirclePlus} className="pe-2" />
      {t('button.add')}
    </>
  );

  if (loading) {
    return <LoadingIndicator size="s" />;
  } if (addedToCalendar) {
    return <FontAwesomeIcon icon={faCheck} className="px-2" />;
  }

  if (minimized) {
    return icon;
  }

  return (
    <Button
      variant="outline-light"
      onClick={handleSubmit}
    >
      {icon}
    </Button>
  );
}

export default AddAgendaItemButton;
