import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode
  length?: number
  completion?: string
};

export function Ellipsis({ children, length = 35, completion = '…' }: Props): JSX.Element {
  if (!(typeof children === 'string')) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return <span>{children.length > length ? `${children.slice(0, length)}${completion}` : children}</span>;
}
