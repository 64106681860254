import { changeEmailPreferencesPath } from '@routes';
import React, { useReducer, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import SaveButton from '@/application/components/SaveButton';
import Layout from '@/application/layouts/Layout';
import { issueReducerAdvanced } from '@/application/pages/email_preferences/advanced/reducers';
import {
  transformCompanyIssuesAdvanced, transformIssuesForSubmissionAdvanced,
} from '@/application/pages/email_preferences/advanced/transformers';
import AgendaCompanyRow from '@/application/pages/email_preferences/agenda/AgendaCompanyRow';
import AgendaEnabled from '@/application/pages/email_preferences/agenda/AgendaEnabled';
import AgendaPreferencesCheckbox from '@/application/pages/email_preferences/agenda/AgendaPreferencesCheckbox';
import LineDivider from '@/shared/components/LineDivider';
import { useAxios } from '@/shared/hooks/useApi';
import { CompanyWithIssuePreferences } from '@/shared/types/emailPreferences';

type Props = {
  agendaEnabled: boolean
  companyIssues: CompanyWithIssuePreferences[]
};

function AgendaSettings({ agendaEnabled, companyIssues }: Props) {
  const { t } = useTranslation();

  const [showIssues, setShowIssues] = useState<boolean>(agendaEnabled);
  const [preferences, dispatch] = useReducer(
    issueReducerAdvanced,
    companyIssues.reduce(transformCompanyIssuesAdvanced, {}),
  );

  const [, submit] = useAxios({
    url: changeEmailPreferencesPath(),
    method: 'POST',
  }, { manual: true });

  const submitPreferences = () => {
    const issues = transformIssuesForSubmissionAdvanced(preferences);

    submit({
      data: {
        user: {
          included_list_ids: issues,
        },
      },
    }).then(() => {
      toast.success(t('email_preferences.saved'));
    }).catch(() => {
      toast.error(t('errors.unknown'));
    });
  };

  return (
    <Layout>
      <div className="d-flex align-items-center mb-3">
        <AgendaEnabled enabled={agendaEnabled} setShowIssues={setShowIssues} />

        {showIssues && (
          <div className="ms-auto d-none d-lg-block">
            <SaveButton onClickHandler={submitPreferences} />
          </div>
        )}
      </div>

      {showIssues && (
        <Card>
          <Card.Body className="p-5">
            <h2 className="mb-0">{t('email_preferences.agenda.issue')}</h2>

            {companyIssues.map((company) => (
              <AgendaCompanyRow key={company.name} company={company} preferences={preferences} dispatch={dispatch} />
            ))}

            <LineDivider />
            <SaveButton onClickHandler={submitPreferences} />
          </Card.Body>
        </Card>
      )}
    </Layout>
  );
}

export default AgendaSettings;
