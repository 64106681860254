import { useEffect } from 'react';

import { usePrevious } from '@/application/hooks/usePrevious';
import { shallowCompare } from '@/shared/utilities/comparison';

/**
 * Triggers callback when given params have changed
 */
export default function useChangedEffect(params, callback) {
  const prevParams = usePrevious(params);

  useEffect(() => {
    const paramsChanged = !shallowCompare(prevParams || {}, params);
    if (paramsChanged) {
      callback();
    }
  }, [prevParams, params]);
}
