import {
  faClock, faLocationDot, faLock, faMap, faUpRightFromSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { exportedParams } from '@/application/atoms/exports';
import AddAgendaItemButton from '@/application/pages/agenda/components/AddAgendaItemButton';
import AgendaItemIssues from '@/application/pages/agenda/components/AgendaItemIssues';
import { Important } from '@/shared/components/Important';
import LineDivider from '@/shared/components/LineDivider';
import { AgendaItem, AgendaItemCompany } from '@/shared/types/models';

type AgendaItemModalProps = {
  showModal: boolean
  closeHandler: () => void
};

function CompanyAdvise({ company, advise }: AgendaItemCompany) {
  const { t } = useTranslation();
  const { agencyName } = useRecoilValue(exportedParams);
  return (
    <div>
      <strong>
        {company.type === 'ProductCompany'
          ? t('title.product_company_advise', { company: company.name })
          : t('title.interpretation_gksv').replace('%{agency}', agencyName)}
      </strong>
      <div
        className="mb-3"
        dangerouslySetInnerHTML={{ __html: advise }}
      />
    </div>
  );
}

export default function AgendaItemModal({
  showModal,
  closeHandler,
  id,
  title,
  desc,
  display_time,
  agenda_category,
  issues,
  source_url,
  location,
  video_url,
  is_cancelled,
  is_private,
  agenda_item_companies,
}: AgendaItemModalProps & AgendaItem) {
  const { t } = useTranslation();
  const agendaItemCompany = agenda_item_companies.find((aic) => aic?.advise?.length > 0);
  const important = agenda_item_companies.some((company) => company.important);

  return (
    <Modal show={showModal} onHide={closeHandler} size="lg">
      <Modal.Body>
        <div className="d-flex align-items-center">
          <h2 className="mb-0 pe-5">
            {important && <Important />}
            {title}
            {is_cancelled && (
            <span className="text-uppercase ms-2">
              (
              {t('message.cancelled')}
              )
            </span>
            )}
          </h2>
          <button
            type="button"
            className="btn-close ms-auto"
            aria-label={t('button.close')}
            onClick={closeHandler}
          />
        </div>

        <LineDivider />

        <div title={t('title.datetime')}>
          <FontAwesomeIcon icon={faClock} className="pe-2" fixedWidth />
          {display_time}
        </div>

        <div>
          <FontAwesomeIcon icon={faLocationDot} className="pe-2" fixedWidth />
          {agenda_category.name}
        </div>

        {source_url && (
        <div className="mb-0" title={t('title.datetime')}>
          <FontAwesomeIcon icon={faUpRightFromSquare} className="pe-2" fixedWidth />
          <a href={source_url}>{t('title.source')}</a>
        </div>
        )}

        {location
                    && (
                    <div className="mb-0">
                      <FontAwesomeIcon icon={faMap} className="pe-2" fixedWidth />
                      <span>{location}</span>
                      {video_url && (
                      <>
                        {' '}
                        [
                        <a href={video_url} target="_blank" rel="noreferrer">{t('title.video')}</a>
                        ]
                      </>
                      )}
                    </div>
                    )}

        {is_private && (
        <div className="mb-0">
          <FontAwesomeIcon icon={faLock} className="pe-2" fixedWidth />
          {t('message.private_event')}
        </div>
        )}

        <LineDivider />

        {desc && <p dangerouslySetInnerHTML={{ __html: desc }} />}

        {agendaItemCompany && <CompanyAdvise {...agendaItemCompany} />}

        <AgendaItemIssues issues={issues} />

        <LineDivider />

        <div className="d-flex justify-content-end align-items-center">
          <AddAgendaItemButton id={id} />
          <Button variant="outline-light" className="ms-2" onClick={closeHandler}>
            {t('button.close')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
