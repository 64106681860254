import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Layout from '@/application/layouts/Layout';
import AgendaFeed from '@/application/pages/home/components/AgendaFeed';

import ArticleFeed from './components/ArticleFeed';

function HomeIndex() {
  return (
    <Layout>
      <Row>
        <Col lg={8}>
          <ArticleFeed />
        </Col>
        <Col lg={4}>
          <AgendaFeed />
        </Col>
      </Row>
    </Layout>
  );
}

export default HomeIndex;
