import {
  faAngleRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AdvancedSettingsLink from '@/application/pages/email_preferences/components/AdvancedSettingsLink';
import ToggleFrequencies from '@/application/pages/email_preferences/components/ToggleFrequencies';
import { EmailPreferenceFrequency } from '@/shared/types/emailPreferences';

interface Props {
  toIssueStep: () => void
  frequencies: EmailPreferenceFrequency
}

function FrequencyStep({ toIssueStep, frequencies }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <h3 className="mb-3">{t('email_preferences.frequency.title')}</h3>
        <p className="text-neutral-90 mb-4">{t('email_preferences.frequency.subtitle')}</p>
      </div>

      <Card>
        <Card.Body className="p-5">
          <ToggleFrequencies
            direct={frequencies.direct}
            daily={frequencies.daily}
            weekly={frequencies.weekly}
            enableToasts={false}
          />

          <div className="d-flex justify-content-end mt-4">
            <Button variant="primary" onClick={toIssueStep}>
              {t('email_preferences.buttons.to_issue_step')}
              <FontAwesomeIcon className="ps-2" icon={faAngleRight} />
            </Button>
          </div>
        </Card.Body>
      </Card>

      <AdvancedSettingsLink />
    </>
  );
}

export default FrequencyStep;
