import * as React from 'react';

import { AgendaIssuePill } from '@/application/components/IssuePill';
import { AgendaItem } from '@/shared/types/models';

interface Props {
  issues: AgendaItem['issues']
  maxIssues?: number
}

function AgendaItemIssues({ issues, maxIssues = 6 }: Props) {
  return (
    <div className="d-inline-flex flex-wrap gap-2">
      {issues.slice(0, maxIssues).map((issue) =>
        <AgendaIssuePill key={issue.id}>{issue.name}</AgendaIssuePill>)}
    </div>
  );
}

export default AgendaItemIssues;
