import { faChevronLeft, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  newUserSessionPath,
  rootPath,
} from '@routes';
import React, { Dispatch, SetStateAction } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { exportedParams } from '@/application/atoms/exports';

import { CountryFlag, DutchFlag, EnglishFlag } from './Flag';

interface TopNavProps {
  setCurrentLanguage: Dispatch<SetStateAction<string>>
  currentLanguage: string
  userId: string
}
interface FaqLanguageToggleProps {
  setCurrentLanguage: Dispatch<SetStateAction<string>>
  currentLanguage: string
}

function FaqLanguageToggle({ setCurrentLanguage, currentLanguage }: FaqLanguageToggleProps) {
  const { t } = useTranslation();
  return (
    <Dropdown className="me-3">
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        <CountryFlag locale={currentLanguage} />
      </Dropdown.Toggle>

      <Dropdown.Menu variant="light">
        <Dropdown.Item href="#" onClick={() => { setCurrentLanguage('nl'); }}>
          <DutchFlag />
          {' '}
          {t('languages.nl')}
        </Dropdown.Item>
        <Dropdown.Item href="#" onClick={() => { setCurrentLanguage('en'); }}>
          <EnglishFlag />
          {' '}
          {t('languages.en')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function TopNav({ setCurrentLanguage, currentLanguage, userId }: TopNavProps) {
  const { t } = useTranslation();
  const { agencyLogoUrl } = useRecoilValue(exportedParams);
  return (
    <div className="topbar bg-white">
      <div className="container topbar-inner-centered d-flex align-items-center justify-content-end">
        <Image src={agencyLogoUrl} className="topbar-logo d-none d-sm-block me-auto" />

        {userId && (
          <a href={rootPath()} className="btn btn-primary">
            <FontAwesomeIcon className="pe-2" icon={faChevronLeft} />
            {t('link.to_dash')}
          </a>
        ) }

        {!userId && (
          <div className="d-flex">
            <FaqLanguageToggle
              setCurrentLanguage={setCurrentLanguage}
              currentLanguage={currentLanguage}
            />
            <a href={newUserSessionPath()} className="btn btn-primary">
              <FontAwesomeIcon className="pe-2" icon={faUser} />
              {t('sign_in.title')}
            </a>
          </div>
        ) }
      </div>
    </div>
  );
}

export default TopNav;
