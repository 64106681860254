import {
  faBookmark, faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiBookmarksPath } from '@routes';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import CreateBookmarkFolderModal from '@/application/pages/bookmarks/components/CreateBookmarkFolderModal';
import { client } from '@/shared/hooks/useApi';
import { BookmarkFolder } from '@/shared/types/models';

interface BookmarkButtonProps {
  bookmarkFolders: BookmarkFolder[];
  articleId: string | number;
}

interface BookmarkButtonDropdownItemProps {
  folder: BookmarkFolder
  toggleDossier: Function
}

function DropdownItem({ folder, toggleDossier }: BookmarkButtonDropdownItemProps) {
  const style = (folder.active) ? 'fa-solid text-primary' : 'fa-regular text-neutral-70';
  const icon = <i className={`${style} text-primary fa-bookmark pe-2`} />;
  return (
    <Dropdown.Item onClick={() => toggleDossier(folder.id, folder.active)} className="bookmark-dropdown-item">
      {icon}
      {folder.name}
    </Dropdown.Item>
  );
}

const toggleDossierRequest = (data) => client.post(apiBookmarksPath(), data);

function BookmarkButton({
  articleId,
  bookmarkFolders,
}: BookmarkButtonProps): JSX.Element {
  const { t } = useTranslation();
  const [folders, setFolders] = useState(bookmarkFolders);
  const [showModal, setShowModal] = useState(false);

  const toggleBookmark = (folderId: string | number, active: boolean) => {
    const data = {
      bookmark_folder_id: folderId,
      article_id: articleId,
    };

    toggleDossierRequest(data)
      .then((res) => {
        setFolders(res.data.data);
        const key = (active) ? 'bookmarks.removed' : 'bookmarks.added';
        toast.success(t(key));
      })
      .catch(() => {
        toast.error(t('bookmarks.error'));
      });
  };

  const newFolderHandler = (folder: BookmarkFolder) => {
    const newFolders = folders.concat(folder);
    setFolders(newFolders);

    toggleBookmark(folder.id, false);
  };

  const listItems = folders.map((folder: BookmarkFolder) => (
    <DropdownItem
      key={folder.id}
      toggleDossier={toggleBookmark}
      folder={folder}
    />
  ));

  return (
    <>
      <Dropdown autoClose="outside">
        <Dropdown.Toggle
          variant="outline-light"
          size="sm"
          className="ms-2"
        >
          <FontAwesomeIcon className="pe-2" icon={faBookmark} />
          {t('control.bookmark')}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {listItems}
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => setShowModal(true)}>
            <FontAwesomeIcon icon={faPlus} className="pe-2" />
            {t('bookmarks.folder.add')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <CreateBookmarkFolderModal
        newFolderHandler={newFolderHandler}
        showModal={showModal}
        closeHandler={() => setShowModal(false)}
      />
    </>
  );
}

export default BookmarkButton;
