import React from 'react';

interface Props {
  text: string,
  show: boolean
  marginStart?: string
}

function IssueDescription({ text, show, marginStart = 'ms-md-6' }: Props) {
  return (
    <div className={`small text-neutral-90 ${marginStart} ps-2 mb-4 ${show ? 'd-block' : 'd-none'}`}>
      {text}
    </div>
  );
}

export default IssueDescription;
