import {
  faPrint,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
  size?: 'sm' | 'lg'
}

function PrintButton({ size }: Props) {
  const { t } = useTranslation();

  const printHandler = () => {
    window.print();
  };

  return (
    <Button variant="outline-light" size={size} className="ms-2" onClick={printHandler}>
      <FontAwesomeIcon className="pe-2" icon={faPrint} />
      {t('title.print')}
    </Button>
  );
}

export default PrintButton;
