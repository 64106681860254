import {
  faCalendarCircleUser,
  faCalendarPlus,
  faEllipsisVertical,
  faPencil,
  faPlus, faShareAlt,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiAgendaFeedPath, apiAgendaFeedsPath, editAgendaFeedPath } from '@routes';
import React, { Dispatch } from 'react';
import {
  Button, Col, Container, Dropdown, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import NothingFound from '@/application/components/NothingFound';
import { PageTitle } from '@/application/components/PageTitle';
import Layout from '@/application/layouts/Layout';
import { AgendaFeedFormModal } from '@/application/pages/agenda_feeds/components/AgendaFeedFormModal';
import DeleteModal from '@/shared/components/DeleteModal';
import { useModalForm } from '@/shared/components/ModalForm';
import { client, useApiCollection } from '@/shared/hooks/useApi';
import { AgendaFeed } from '@/shared/types/models';
import { icalAgendaFeedPath } from '@/shared/utilities/agendaFeeds';

type AgendaFeedRowProps = AgendaFeed & {
  dispatch: Dispatch<any>
};

function AgendaFeedRow({ dispatch, ...feed }: AgendaFeedRowProps) {
  const { t } = useTranslation();
  const { id, title, categories } = feed;
  return (
    <div className="d-flex flex-column bb-neutral-10 pb-3">
      <div className="d-flex flex-fill">
        <div className="d-flex flex-column text-primary-1 flex-fill">
          <div className="agenda-feed-title fw-medium">
            <a href={editAgendaFeedPath(id)}>{title}</a>
          </div>
          <div className="flex-fill small text-neutral-70" title={categories.map((c) => c.name).join(', ')}>
            {categories.length === 0 && t('agenda_feeds.headings.all_categories')}
            {categories.length > 0 && `${categories.length} ${t('views.agendas.categories').toLowerCase()}`}
          </div>
        </div>
        <div className="agenda-feed-options d-flex flex-column justify-content-center">
          <div className="d-flex">
            <Button
              variant="outline-light"
              className="me-1"
              size="sm"
              href={icalAgendaFeedPath(id)}
              target="_blank"
            >
              <FontAwesomeIcon icon={faCalendarPlus} className="pe-2" />
              {t('agenda_feeds.add_to_calendar_button')}
            </Button>
            <Dropdown align="end">
              <Dropdown.Toggle
                variant="outline-light"
                size="sm"
                className="no-after"
              >
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className="small"
                  onClick={() => dispatch({
                    type: 'edit',
                    values: {
                      id,
                      title,
                      categories,
                      agenda_category_ids: categories.map((c) => c.id),
                    },
                  })}
                >
                  <FontAwesomeIcon
                    size="sm"
                    className="me-2"
                    icon={faPencil}
                  />
                  {t('button.edit')}
                </Dropdown.Item>
                <Dropdown.Item
                  className="small"
                  onClick={() => {
                    navigator.clipboard.writeText(icalAgendaFeedPath(id));
                    toast.success(t('agenda_feeds.messages.url_copied_to_clipboard'));
                  }}
                >
                  <FontAwesomeIcon
                    size="sm"
                    className="me-2"
                    icon={faShareAlt}
                  />
                  {t('control.share')}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  className="small text-danger"
                  onClick={() => dispatch({
                    type: 'destroy',
                    values: { id, name: title },
                  })}
                >
                  <FontAwesomeIcon
                    size="sm"
                    className="me-2"
                    icon={faTrash}
                  />
                  {t('button.delete')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

function NewAgendaFeedButton({ label, onClick }: { label?: string, onClick: () => void }) {
  const { t } = useTranslation();
  return (
    <Button onClick={onClick} size="sm">
      <FontAwesomeIcon icon={faPlus} className="pe-2" />
      {label || t('agenda_feeds.create_feed')}
    </Button>
  );
}

const defaultInitialValues = { title: '', agenda_category_ids: [] };

function AgendaFeedsList() {
  const { t } = useTranslation();
  const {
    state: { mode: formMode, show: showModal, values: formValues },
    dispatch: dispatchModal,
    create,
    dismiss,
  } = useModalForm({ defaultInitialValues });
  const [{ items }, { loading }, refetch] = useApiCollection({ url: apiAgendaFeedsPath({ per: 50 }) });
  const showFormModal = showModal && (formMode === 'edit' || formMode === 'create');
  const showDeleteModal = showModal && formMode === 'destroy';
  return (
    <>
      {!loading && items.length > 0 && (
        <div className="d-flex flex-column rounded-2 bg-white p-5 gap-3">
          {items.map((item: AgendaFeed) => (
            <AgendaFeedRow
              key={item.id}
              dispatch={dispatchModal}
              {...item}
            />
          ))}
          <div className="pt-3">
            <NewAgendaFeedButton onClick={create} />
          </div>
        </div>
      )}
      {!loading && items.length === 0 && (
        <NothingFound
          icon={faCalendarCircleUser}
          title={t('agenda_feeds.none_exist')}
          subtitle={t('agenda_feeds.none_exist_subtitle')}
          button={(
            <NewAgendaFeedButton
              onClick={create}
              label={t('agenda_feeds.create_first_feed')}
            />
          )}
        />
      )}
      {showFormModal && (
        <AgendaFeedFormModal
          mode={formMode}
          values={formValues}
          onHide={dismiss}
          onSave={refetch}
          show
        />
      )}
      {showDeleteModal && (
      <DeleteModal
        title={t('message.confirm_deletion')}
        body={t('delete_modal.are_you_sure', { name: formValues.name })}
        closeHandler={dismiss}
        confirmHandler={() => client
          .delete(apiAgendaFeedPath(formValues.id))
          .then(() => {
            refetch().then(dismiss);
            toast.success(t('confirmations.title'));
          }).catch(() => {
            toast.error(t('errors.unknown'));
          })}
        show
      />
      )}
    </>
  );
}

function AgendaFeedsIndex() {
  const { t } = useTranslation();
  return (
    <Layout>
      <Container>
        <Row>
          <Col sm={{ offset: 1, span: 10 }}>
            <PageTitle>{t('title.agenda_feeds')}</PageTitle>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm={{ offset: 1, span: 10 }}>
            <AgendaFeedsList />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default AgendaFeedsIndex;
