import { apiAgendaCategoriesPath } from '@routes';
import { useField, useFormikContext } from 'formik';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import LoadingIndicator from '@/shared/components/LoadingIndicator';
import { ErrorMessage } from '@/shared/form/ErrorMessage';
import { useApiCollection } from '@/shared/hooks/useApi';
import { AgendaCategory, AgendaFeed, Model } from '@/shared/types/models';
import { objectExcluding } from '@/shared/utilities/object';
import { modelSelection } from '@/shared/utilities/selection';

type AgendaCategorySelectFieldProps = {
  name: string,
  label?: string | JSX.Element,
  placeholder?: string
} & HTMLAttributes<HTMLDivElement>;

export function AgendaCategorySelectField({
  name, label, placeholder, ...props
}: AgendaCategorySelectFieldProps) {
  const { t } = useTranslation();
  const { values } = useFormikContext<AgendaFeed>();
  const [, { value, touched, error }, { setValue }] = useField<Array<Model['id']>>(name);
  const [search, setSearch] = useState('');

  const [{ items: categories }, { loading }] = useApiCollection<AgendaCategory>({
    url: apiAgendaCategoriesPath({ q: search, per: 50 }),
  });

  const [selectedCategories, setSelectedCategories] = useState<Record<Model['id'], AgendaCategory>>(
    modelSelection(values.categories || []),
  );

  useEffect(() => {
    setValue(
      Object
        .values(selectedCategories)
        .map((c) => c.id),
    );
  }, [selectedCategories]);

  useEffect(() => {
    // required to reset selection after form reset
    if (value.length === 0 && Object.keys(selectedCategories).length > 0) {
      setSelectedCategories({});
    }
  }, [value]);

  return (
    <Form.Group {...props}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        placeholder={placeholder || t('placeholder.search')}
        onChange={(e) => setSearch(e.target.value)}
      />
      <p className="small text-neutral-70">{t('agenda_feeds.headings.categories_explanation')}</p>
      <ul className="d-inline-flex flex-wrap list-unstyled gap-2 mt-3 mb-0">
        {Object.values(selectedCategories).map((c) => (
          <li
            key={c.id}
            role="option"
            aria-selected
            className="agenda-feed-category active cursor-pointer"
            onKeyDown={() => null}
            onClick={() => setSelectedCategories((selected) => objectExcluding(selected, c.id))}
          >
            {c.name}
          </li>
        ))}
        {categories.filter((c) => !selectedCategories[c.id]).map((c) => (
          <li
            key={c.id}
            role="option"
            aria-selected={false}
            className="agenda-feed-category cursor-pointer"
            onKeyDown={() => null}
            onClick={() => setSelectedCategories((selected) => ({ ...selected, [c.id]: c }))}
          >
            {c.name}
          </li>
        ))}
      </ul>
      {loading && <LoadingIndicator />}
      {touched && error ? (
        <ErrorMessage error={error} />
      ) : null}
    </Form.Group>
  );
}
