import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function Important() {
  const { t } = useTranslation();
  return (
    <span
      title={t('views.agendas.marked_important')}
      className="me-1 text-secondary fw-bold"
    >
      <FontAwesomeIcon icon={faExclamationCircle} />
    </span>
  );
}
