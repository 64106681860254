import {
  faBox,
  faBuilding,
  faNewspaper,
  faShareAlt,
  faTag,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  apiArticlesPath,
  apiIssuesPath,
  apiPeoplePath,
  apiProductsPath,
  apiStakeholdersPath,
  articlePath,
} from '@routes';
import React, {
  Dispatch, useState,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { CompactSwitch } from '@/application/components/CompactSwitch';
import { FetchMoreButton } from '@/application/components/FetchMoreButton';
import {
  exportFilters,
  Filter,
  FilterAction,
  FilterOption,
  FilterOptionProps,
  FilterPill,
  filterReducerWithDefault,
  Filters,
  ResetFiltersButton,
  useFilter,
} from '@/application/components/Filter';
import { IssuePill } from '@/application/components/IssuePill';
import NothingFound from '@/application/components/NothingFound';
import { PageTitle } from '@/application/components/PageTitle';
import { SearchBar } from '@/application/components/SearchBar';
import VisibilityDetector from '@/application/components/VisibilityDetector';
import Layout from '@/application/layouts/Layout';
import ShareButton from '@/application/pages/articles/components/ShareButton';
import {
  DateRangeSelector, exportDateRange, namedRanges, useNamedRanges,
} from '@/shared/components/DateRangeSelector';
import DateTimeLocalized from '@/shared/components/DateTimeLocalized';
import LoadingIndicator from '@/shared/components/LoadingIndicator';
import { client, useApiCollection } from '@/shared/hooks/useApi';
import { useCursor, useNextCursor } from '@/shared/hooks/useCursor';
import {
  Article, Issue, Model, Person, Product, Stakeholder,
} from '@/shared/types/models';
import { toQueryString } from '@/shared/utilities/querystring';

function ArticleRow({
  id, title, published_at, summary_truncated, issues, compact = false,
}: Article & { compact?: boolean }) {
  const { t } = useTranslation();

  return (
    <Row className={`article-row soh-container mx-0 px-2 ${compact ? 'py-4' : 'py-5'}`} role="article">
      <Col lg={4} className="d-flex flex-column align-items-lg-end">
        <div className={`fw-medium small text-primary-shade-2 ${compact ? '' : 'mb-3'}`}>
          <DateTimeLocalized format="D MMMM YYYY">{published_at}</DateTimeLocalized>
        </div>
        <div className={`d-inline-flex flex-wrap justify-content-lg-end gap-2 ms-lg-4 mb-3 ${compact ? 'd-none' : ''}`}>
          {issues.slice(0, 6).map((issue) =>
            <IssuePill id={issue.id} key={issue.id}>{issue.name}</IssuePill>)}
          {issues.length > 6 && <div title={issues.slice(6).map((issue) => issue.name).join(', ')}>...</div>}
        </div>
        {!compact && (
          <div className="d-none d-lg-block">
            <div className="gap-2 soh-item">
              <ShareButton
                articleId={id}
                articleTitle={title}
                label={<FontAwesomeIcon icon={faShareAlt} size="sm" title={t('control.share')} />}
                className="px-2 py-1"
                toggleBsPrefix="dropdown-toggle dropdown-toggle--clean"
              />
            </div>
          </div>
        )}
      </Col>
      <Col lg={8}>
        <h3 className={`article-title ${compact ? 'mb-0' : 'mb-3'}`}>
          <a href={articlePath(id)} className="text-primary-shade-1">{title}</a>
        </h3>
        {/* eslint-disable-next-line react/no-danger */}
        {!compact && <small dangerouslySetInnerHTML={{ __html: summary_truncated }} />}
      </Col>
    </Row>
  );
}

function ArticleList({
  params = {},
  compact = false,
  suspended = true,
}: { compact?: boolean, params?: Record<string, unknown>, suspended?: boolean }) {
  const { t } = useTranslation();
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [cursor, setCursor] = useCursor(params);
  const [{ items, hasNext }, { loading }] = useApiCollection<Article>(
    {
      url: `${apiArticlesPath()}?${toQueryString(cursor ? { ...params, cursor } : params)}`,
      // cursor is set to null when (filter) params change, disabling accumulation
      // and therefore 'resetting' the resultset instead of appending to existing
      accumulate: cursor !== null,
    },
    {
      // not firing requests until component is unsuspended
      // unsuspension is typically done by a filter being initialized
      manual: suspended,
    },
  );
  const isReady = !loading && !suspended;
  const nextCursor = useNextCursor('-published_at', items);
  const fetchNext = () => setCursor(nextCursor);

  return (
    <>
      <div className="rounded-2 bg-white">
        {items.map((item) => (
          <ArticleRow
            compact={compact}
            key={item.id}
            {...item}
          />
        ))}
      </div>
      <div className="text-center my-4">
        {!isReady && <LoadingIndicator />}
        {isReady && !hasNext && items.length === 0 && (
          <NothingFound
            icon={faNewspaper}
            title={t('message.no_articles_found')}
            subtitle={t('message.no_articles_found_explanation')}
          />
        )}
        {!hasNext && !loading && items.length > 0 && <p>{t('message.no_more_articles')}</p>}
        {isReady && hasNext && (showLoadMore || compact) && <FetchMoreButton onClick={fetchNext} />}
      </div>
      {isReady && items.length > 0 && (
        <VisibilityDetector
          onVisibilityStart={fetchNext}
          onVisibilityStop={() => setShowLoadMore(false)}
          onVisibilityInitialize={setShowLoadMore}
        />
      )}
    </>
  );
}

type ArticleFilters = Filters<Person | Stakeholder | Issue | Product>;

function defaultArticleFilters(): ArticleFilters {
  return {
    Person: {},
    Stakeholder: {},
    Issue: {},
    Product: {},
  };
}

const articleFilterReducer = filterReducerWithDefault(defaultArticleFilters);

async function articleFilterInitializer(dispatch) {
  const promises = [];
  const urlSearchParams = new URLSearchParams(window.location.search);

  if (urlSearchParams.has('issue_id')) {
    promises.push(
      client.get(apiIssuesPath({ pk: urlSearchParams.getAll('issue_id') }))
        .then((r) => dispatch({ type: 'import', itemType: 'Issue', items: r.data.data })),
    );
  }

  if (urlSearchParams.has('product_id')) {
    promises.push(
      client.get(apiProductsPath({ pk: urlSearchParams.getAll('product_id') }))
        .then((r) => dispatch({ type: 'import', itemType: 'Product', items: r.data.data })),
    );
  }

  if (urlSearchParams.has('person_id')) {
    promises.push(
      client.get(apiPeoplePath({ pk: urlSearchParams.getAll('person_id') }))
        .then((r) => dispatch({ type: 'import', itemType: 'Person', items: r.data.data })),
    );
  }

  if (urlSearchParams.has('stakeholder_id')) {
    promises.push(
      client.get(apiStakeholdersPath({ pk: urlSearchParams.getAll('stakeholder_id') }))
        .then((r) => dispatch({ type: 'import', itemType: 'Stakeholder', items: r.data.data })),
    );
  }

  await Promise.all(promises);
}

type ArticleListOptionsProps = {
  dispatch: Dispatch<FilterAction<Model>>
  filters: ArticleFilters,
  onCompactToggle: (b: boolean) => void
};

function ArticleListOptions({ dispatch, filters, onCompactToggle } : ArticleListOptionsProps) {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-wrap gap-2">
      <Filter
        label={t('filters.persons_of_interest')}
        url={apiPeoplePath()}
        renderOption={({ id, full_name, ...props }: Person & FilterOptionProps) =>
          <FilterOption key={id} icon={faUser} label={full_name} {...props} />}
        onSelectItem={(item: Person) => dispatch({ type: 'select', itemType: 'Person', item })}
        selected={filters.Person}
      />
      <Filter
        label={t('filters.stakeholders')}
        url={apiStakeholdersPath()}
        renderOption={({ id, name, ...props }: Stakeholder & FilterOptionProps) =>
          <FilterOption key={id} icon={faBuilding} label={name} {...props} />}
        onSelectItem={(item: Stakeholder) => dispatch({ type: 'select', itemType: 'Stakeholder', item })}
        selected={filters.Stakeholder}
      />
      <Filter
        label={t('filters.issues')}
        url={apiIssuesPath()}
        renderOption={({ id, name, ...props }: Issue & FilterOptionProps) =>
          <FilterOption key={id} icon={faTag} label={name} {...props} />}
        onSelectItem={(item: Issue) => dispatch({ type: 'select', itemType: 'Issue', item })}
        selected={filters.Issue}
      />
      <Filter
        label={t('filters.products')}
        url={apiProductsPath()}
        renderOption={({ id, name, ...props }: Product & FilterOptionProps) =>
          <FilterOption key={id} icon={faBox} label={name} {...props} />}
        onSelectItem={(item: Product) => dispatch({ type: 'select', itemType: 'Product', item })}
        selected={filters.Product}
      />

      <CompactSwitch className="ms-xl-auto" onChange={(e) => onCompactToggle(e.target.checked)} />
    </div>
  );
}

type ArticleActiveFilterProps = {
  filters: ArticleFilters,
  dispatch: Dispatch<FilterAction<Model>>
};

function ArticleActiveFilters({ filters, dispatch }: ArticleActiveFilterProps) {
  return (
    <div className="d-inline-flex flex-wrap gap-2 mt-2">
      {Object.values(filters.Person).map((item: Person) => (
        <FilterPill
          key={item.id}
          icon={faUser}
          dispatch={dispatch}
          itemType="Person"
          item={item}
        >
          {item.full_name}
        </FilterPill>
      ))}
      {Object.values(filters.Stakeholder).map((item: Stakeholder) => (
        <FilterPill
          key={item.id}
          icon={faBuilding}
          dispatch={dispatch}
          itemType="Stakeholder"
          item={item}
        >
          {item.name}
        </FilterPill>
      ))}
      {Object.values(filters.Issue).map((item: Issue) => (
        <FilterPill
          key={item.id}
          icon={faTag}
          dispatch={dispatch}
          itemType="Issue"
          item={item}
        >
          {item.name}
        </FilterPill>
      ))}
      {Object.values(filters.Product).map((item: Product) => (
        <FilterPill
          key={item.id}
          icon={faBox}
          dispatch={dispatch}
          itemType="Product"
          item={item}
        >
          {item.name}
        </FilterPill>
      ))}
      <ResetFiltersButton dispatch={dispatch} />
    </div>
  );
}

function ArticlesIndex() {
  const { t } = useTranslation();
  const [renderCompact, setRenderCompact] = useState(false);
  const [search, setSearch] = useState('');
  const {
    range,
    setRange,
    setRangeFromName,
    reset: resetRange,
    name: rangeName,
  } = useNamedRanges(namedRanges);
  const {
    filters,
    hasFilters,
    dispatch,
    initializing: filterInitializing,
  } = useFilter({
    reducer: articleFilterReducer,
    initialState: defaultArticleFilters(),
    initializer: articleFilterInitializer,
  });
  return (
    <Layout>
      <Row>
        <Col sm={{ offset: 1, span: 10 }}>
          <PageTitle>{t('title.articles')}</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col sm={{ offset: 1, span: 10 }}>
          <SearchBar
            onChange={(e) => setSearch(e.target.value)}
            Suffix={(
              <DateRangeSelector
                range={range}
                rangeName={rangeName}
                resetRange={resetRange}
                setRange={setRange}
                setRangeFromName={setRangeFromName}
              />
)}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={{ offset: 1, span: 10 }}>
          <ArticleListOptions
            dispatch={dispatch}
            filters={filters}
            onCompactToggle={setRenderCompact}
          />
        </Col>
      </Row>
      {hasFilters && (
        <Row>
          <Col sm={{ offset: 1, span: 10 }}>
            <ArticleActiveFilters
              dispatch={dispatch}
              filters={filters}
            />
          </Col>
        </Row>
      )}
      <Row className="mt-4">
        <Col sm={{ offset: 1, span: 10 }}>
          <ArticleList
            compact={renderCompact}
            params={{
              search,
              ...exportDateRange(range),
              ...exportFilters(filters),
            }}
            suspended={filterInitializing}
          />
        </Col>
      </Row>
    </Layout>
  );
}

export default ArticlesIndex;
