import { useState } from 'react';

function useEmailScreen(defaultScreen = 'NewUser') {
  const [screen, updateScreen] = useState(defaultScreen);

  const toNewUser = () => updateScreen('NewUser');
  const toFrequencyStep = () => updateScreen('FrequencyStep');
  const toIssueStep = () => updateScreen('IssueStep');
  const toStakeholderStep = () => updateScreen('StakeholderStep');
  const toConfirmStep = () => updateScreen('ConfirmStep');

  return {
    screen, toNewUser, toFrequencyStep, toIssueStep, toStakeholderStep, toConfirmStep,
  };
}

export default useEmailScreen;
