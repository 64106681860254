import 'bootstrap';

import { config } from '@fortawesome/fontawesome-svg-core';

import sentryInit from '@/shared/sentry';
import { jargonTooltipsInit, tooltipsInit } from '@/shared/tooltips';

sentryInit();
tooltipsInit();
jargonTooltipsInit();

// Required to make FontAwesome CSP compatible: https://fontawesome.com/docs/web/dig-deeper/security
config.autoAddCss = false;

// this will allow for <%= react_component('ArticleFeed') %>
const ReactRailsUJS = require('react_ujs');

const componentRequireContext = require.context('application', true, /^((?!test).)*$/);
ReactRailsUJS.useContext(componentRequireContext);
