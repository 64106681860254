import {
  faAngleRight, faEnvelopeOpenText,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
  nextStep: () => void
}

function NewUser({ nextStep }: Props) {
  const { t } = useTranslation();

  return (
    <Row className="pt-5">
      <Col lg={{ offset: 3, span: 6 }}>
        <div className="d-flex justify-content-center py-5">
          <FontAwesomeIcon className="text-neutral-60" icon={faEnvelopeOpenText} size="6x" />
        </div>

        <h3>{t('email_preferences.new_user.title')}</h3>
        <p>{t('email_preferences.new_user.body')}</p>
        <Button variant="primary" onClick={nextStep}>
          {t('email_preferences.new_user.start_button')}
          <FontAwesomeIcon className="ps-2" icon={faAngleRight} />
        </Button>
      </Col>
    </Row>
  );
}

export default NewUser;
