import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
  boolean: boolean
}

function BooleanIndicator({ boolean, ...props }: Props) {
  const icon = (boolean) ? faCheck : faTimes;
  return <FontAwesomeIcon icon={icon} {...props} />;
}

export default BooleanIndicator;
