import {
  faGears,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { advancedEmailPreferencesPath } from '@routes';
import React from 'react';
import { useTranslation } from 'react-i18next';

function AdvancedSettingsLink() {
  const { t } = useTranslation();
  return (
    <div className="my-3">
      <a className="text-neutral-90 cursor-pointer small" href={advancedEmailPreferencesPath()}>
        <FontAwesomeIcon className="pe-2" icon={faGears} />
        {t('email_preferences.buttons.to_advanced_settings')}
      </a>
    </div>
  );
}

export default AdvancedSettingsLink;
