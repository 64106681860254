import React, { Dispatch } from 'react';

interface Props {
  id: string
  schedule: 'direct' | 'daily' | 'weekly'
  state: boolean
  label: string
  dispatch: Dispatch<any>
}

function EmailPreferencesCheckbox({
  id, schedule, state, label, dispatch,
}: Props) {
  const handleStateChange = () => {
    dispatch({
      type: 'toggle', id, schedule, state,
    });
  };

  const labelId = `${id}-schedule`;

  return (
    <>
      <input
        id={labelId}
        className="form-check-input form-check-input-lg mt-0"
        type="checkbox"
        checked={state}
        onChange={handleStateChange}
      />
      <label htmlFor={labelId} className="visually-hidden">{label}</label>
    </>
  );
}

export default EmailPreferencesCheckbox;
