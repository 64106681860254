import {
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

function NoEmailWarning() {
  const { t } = useTranslation();

  return (
    <div className="no-email-warning">
      <FontAwesomeIcon className="pe-2 text-info" icon={faInfoCircle} />
      <span className="text-neutral-90">{t('email_preferences.flash.you_will_receive_no_emails')}</span>
    </div>
  );
}

export default NoEmailWarning;
