import {
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiFeedbackEventsPath } from '@routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import useStoreFeedback from '@/application/hooks/useStoreFeedback';
import { useAxios } from '@/shared/hooks/useApi';

interface Props {
  articleId: number | string
}

function ThumbsUpDownFeedback({ articleId }: Props) {
  const { t } = useTranslation();

  const [, submit] = useAxios({
    url: apiFeedbackEventsPath(),
    method: 'POST',
  }, { manual: true });

  const [feedback, storeFeedback] = useStoreFeedback('article', articleId);

  const sendFeedback = (grade: string) => {
    submit({
      data: {
        grade,
        metric: 'quality',
        subject_type: 'Article',
        subject_id: String(articleId),
      },
    }).then(() => {
      storeFeedback(grade);
      toast(t('feedback.thanks'));
    }).catch(() => {
      toast.error(t('errors.unknown'));
    });
  };

  return (
    <>
      <h3 className="mb-2">{t('feedback.statement')}</h3>
      <FontAwesomeIcon
        className={`pe-3 fs-2 cursor-pointer ${(feedback === 'thumbs up') ? 'text-success' : ''}`}
        icon={faThumbsUp}
        onClick={() => sendFeedback('thumbs up')}
      />
      <FontAwesomeIcon
        className={`cursor-pointer fs-2 ${(feedback === 'thumbs down') ? 'text-danger' : ''}`}
        icon={faThumbsDown}
        onClick={() => sendFeedback('thumbs down')}
      />
    </>
  );
}

export default ThumbsUpDownFeedback;
