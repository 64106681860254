import React, { lazy, Suspense } from 'react';

/**
 * Component that lazy loads a page, triggering smaller (but more) bundles
 */
function LazyLoader({ component, ...extraProps }: { component: string } & object) {
  const Component = lazy(() => import(`./${component}`));
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component {...extraProps} />
    </Suspense>
  );
}

export default LazyLoader;
